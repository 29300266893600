import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import GoToTop from "../../components/GoToTop";
import { Button, Container } from "react-bootstrap";
import { Card, Accordion } from "react-bootstrap";
import "./OurProductsNew.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Spinner from "../../images/loader.gif";
import { imgUrlData } from "../../urlData";
import MetaDecorator from "../../componentsnew/MetaDecorator/MetaDecorator";
import config from "../../config/config";

const OurProductsNew = () => {
  //initial productList
  const [bannerData, setBannerData] = useState({});
  const [productList, setProductList] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [checkBoxesArray, setcheckBoxesArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(true);

  const base_url = config.api_url;

  //Fetch products API
  const getProductsData = async () => {
    // axios
    //   .get(`${base_url}/product/products`)
    //   .then((res) => {
    //     setProductList(res.data);
    //     setFilteredProducts(res.data);

    //     let newArrayOfCheckBoxes = res.data.map((item, index) => {
    //       return item.category;
    //     });
    //     newArrayOfCheckBoxes = [...new Set(newArrayOfCheckBoxes)];
    //     setcheckBoxesArray(newArrayOfCheckBoxes);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error, "error fetching products API");
    //   });

    try {
      const res = await axios.get(`${base_url}/product/products`);
      console.log(res.data);

      const categoryres = await axios.get(`${base_url}/product/allcategory`);
      console.log(categoryres.data);
      setProductList(res.data);
      setFilteredProducts(res.data);
      setcheckBoxesArray(categoryres.data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error fetching products API");
    }
  };

  //Fetch call to get product banner
  const getProductBanner = () => {
    axios
      .get(`${base_url}/product/banners`)
      .then((res) => {
        setBannerData(res.data[0]);
      })
      .catch((error) => {
        console.log(error, "error fetching product banner");
      });
  };

  // window dimensions
  const { width } = useWindowDimensions();
  useEffect(() => {
    getProductBanner();
    getProductsData();
  }, []);
  const applyScroll = () => {
    const yOffset = -120;

    const element = document.getElementById(
      width >= 992 ? "productssec2" : "productsCol2"
    );
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const handleAllRadioBtnChange = (e) => {
    applyScroll();
    setIsChecked(true);
    if (e.target.value === "ALL") {
      setFilteredProducts(productList);
    }
  };

  const handleCategoryChange = (e) => {
    // applyScroll();
    const filterProductsArr = productList.filter(
      (product) => product.category === e.target.value
    );
    setIsChecked(false);
    setFilteredProducts(filterProductsArr);
  };

  const handleSubCategoryChange = (e) => {
    // applyScroll();
    const filterProductsArr = productList.filter(
      (product) => product.subCategory === e.target.value
    );
    setIsChecked(false);
    setFilteredProducts(filterProductsArr);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  // function CustomToggle({ children, eventKey }) {
  //   const decoratedOnClick = useAccordionToggle(eventKey, () =>
  //     console.log('totally custom!'),
  //   );

  const categoryList = checkBoxesArray.map((value, index) => {
    return (
      <>
      <div className="category_flex">
        <div key={index} className="checkboxContainer checkboxnew py-1">
          <div className="boxParent form-group">
            <input
              type="radio"
              id={value.title}
              name="cat_filter"
              value={value.title}
              onChange={(e) => handleCategoryChange(e)}
              className="box"
            />
            <label htmlFor={value.title} className="boxText">
              <span className="label_text">{value.title}</span>
            </label>
          </div>
        </div>
        <Accordion.Toggle
          className={
            value.subcategories.length > 0
              ? `accordionToggleHeader2`
              : `accordionToggleMain`
          }
          
          as={Card.Header}
          // variant="link"
          eventKey={index + 1}
        >
          {/* <span className="spacer"></span> */}
          <span></span>
        </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey={index + 1}>
          <>
            {value.subcategories.length > 0 &&
              value.subcategories.map((subcatgeory, index) => (
                <div
                  key={index}
                  className="checkboxContainer py-1 sub_checkboxContainer"
                >
                  <div className="boxParent form-group">
                    <input
                      type="radio"
                      id={subcatgeory.title}
                      name="cat_filter"
                      value={subcatgeory.title}
                      onChange={(e) => handleSubCategoryChange(e)}
                      className="box"
                    />
                    <label htmlFor={subcatgeory.title} className="boxText">
                      <span className="label_text">{subcatgeory.title}</span>
                    </label>
                  </div>
                </div>
              ))}
          </>
        </Accordion.Collapse>
      </>
    );
  });

  if (loading) {
    return (
      <div className="Spinner">
        <img src={Spinner} alt="spinner" loading="lazy" />
      </div>
    );
  }

  return (
    <>
      <MetaDecorator
        metaTitle="Laxmi - Products - High Quality, Affordable & Authentic Foods"
        metaDesc="A trusted provider of quality Flour, Beans, Cereals, Rice, Spices and a lot more across North America, helping you stay connected with your culture."
        canonicalLink="https://laxmihos.com/products"
      />

      <div className="MainContent">
        <div className="banner-container">
          <img
            src={
              width < 768
                ? `${imgUrlData}${bannerData.mobileImageUrl}`
                : `${imgUrlData}${bannerData.desktopImageUrl}`
            }
            alt="banner"
            className="banner-img"
          />
          <h1 className="banner-title f-DVB-B-70 our-story-title">
            {bannerData.text}
          </h1>
        </div>
        <Container
          className="my_container ourproductssection2"
          id="productssec2"
        >
          <div className="row">
            <div
              className="col-lg-3"
              data-aos="fade-right"
              // data-aos-delay="600"
              data-aos-duration="1400"
            >
              <div className="filterByContainer">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header className="accordionHeader">
                      <Accordion.Toggle
                        className="accordionToggleHeader"
                        as={Card.Header}
                        // variant="link"
                        eventKey="0"
                      >
                        Filter By
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Card.Text className="text-start px-3 categoriestitle">
                          Categories
                        </Card.Text>
                        <form className="productCategoryForm">
                          {/* {checkBoxesArray ? ( */}
                          <div
                            key="ALLFilterCheckbox"
                            className="checkboxContainer py-1"
                          >
                            <div className="boxParent form-group">
                              <input
                                type="radio"
                                id="ALL"
                                name="cat_filter"
                                value="ALL"
                                checked={isChecked ? true : false}
                                onChange={(e) => handleAllRadioBtnChange(e)}
                                className="box"
                              />
                              <label htmlFor="ALL" className="boxText">
                                <span className="label_text">All</span>
                              </label>
                            </div>
                          </div>
                          {/* // ) : null} */}
                          {/* {checkBoxesArray ?*/}
                          {/* {checkBoxesArray.map((value, index) => {
                            return (
                              <div
                                key={index}
                                className="checkboxContainer px-3 py-1"
                              >
                                <div className="boxParent form-group">
                                  <input
                                    type="radio"
                                    id={value.title}
                                    name="cat_filter"
                                    value={value.title}
                                    onChange={(e) => handleCategoryChange(e)}
                                    className="box"
                                  />
                                  <label
                                    htmlFor={value.title}
                                    className="boxText"
                                  >
                                    {value.title}
                                  </label>
                                </div>
                              </div>
                            );
                          })} */}

                          <Accordion>{categoryList}</Accordion>

                          {/* : null} */}
                        </form>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
            <div className="col-lg-9" id="productsCol2">
              <div className="productsWrapper">
                <Container className="">
                  <span className="productsSize">
                    {" "}
                    {filteredProducts.length} PRODUCTS
                  </span>
                </Container>
                <Container className="productContainer py-lg-5">
                  {/* {filteredProducts?  */}
                  {filteredProducts.map((product, index) => {
                    return (
                      <div key={index} className="productCardContainer">
                        <Card
                          className="productCard"
                          data-aos="fade-up"
                          // data-aos-delay="600"
                          data-aos-duration="1400"
                        >
                          <Card.Img
                            className="productImage"
                            src={
                              width < 768
                                ? `${base_url}/public/${product.desktopImageUrl}`
                                : `${base_url}/public/${product.desktopImageUrl}`
                            }
                          />
                          <Card.Body>
                            <Card.Title
                              className="productTitle"
                              // style={{ color: "#1A1818" }}
                            >
                              {product.title}
                            </Card.Title>
                            <Card.Text className="productDescription">
                              {product.description}
                            </Card.Text>
                            <Card.Text className="productUnit my-2">
                              {/* Available in {v.unit} */}
                              Available in{" "}
                              {product.title == "Peanuts"
                                ? `${product.unit} Blanched 100g, 200g, 400g, 2lb, 4lb`
                                : product.unit}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                  {/* // : null} */}
                </Container>
              </div>
            </div>
          </div>
        </Container>

        <GoToTop />
      </div>
    </>
  );
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default OurProductsNew;
