import React from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({ metaTitle, metaDesc, canonicalLink }) => {
  return (
    <Helmet>
      <link rel="canonical" href={canonicalLink}></link>
      <meta name="robots" content="index, follow" />
      <meta name="description" content={metaDesc} />
      <title>{metaTitle}</title>
    </Helmet>
  );
};

export default MetaDecorator;
