export const Data = [
  {
    title: "Sharbati Flour",
    faqs: [
      {
        question: "What is Sharbati flour?",
        answer:
          "It is a regional variety of atta, derived from the wheat grown in the Sehore and Vidisha regions of MP and it's grains are bigger in size with a golden sheen to it. This atta is sweeter in taste and has a better texture compared to other varieties of atta.",
      },
      {
        question: "How is Sharbati flour different from normal flour?",
        answer:
          "Also known as the MP wheat, Sharbati atta is sweeter in taste and better in texture. ... Being rain water irrigated, the soil for Sharbati wheat is high in potash content and low in humidity. This amazingly ends up increasing the protein content of the wheat by almost 2% more as compared to the wheat atta. 20, Rotis/chapatis made using Sharbati atta are much softer, tastier and healthier when compared to the normal wheat atta, Sharbati wheat flour is no doubt a healthy choice because these wheats are cultivated without the usage of any pesticides and insecticides. A good source of fiber and iron sharbati atta yields tasty golden rotis that stay soft all day long.",
      },
      {
        question: "Why are there 2 types of Sharbati flour?",
        answer:
          "Sharbati wheat is a regional variety of atta, derived from the wheat grown in the Sehore and Vidisha regions of MP.The moisture content of sharbati wheat ismore than lokwan wheat .Sharbati wheat is a regional variety of atta, derived from the wheat grown in the Sehore and Vidisha regions of MP. Also known as the MP wheat, Sharbati atta is sweeter in taste and better in texture.",
      },
      {
        question: "How do you identify Sharbati wheat?",
        answer:
          "Sharbati gehu gets it name from the region in which it is grown in Madhya Pradesh. ... This is a low sugar low gluten variety of wheat that can be consumed daily by diabetic and heart patients in consultation with medical practictionars* The Golden Grain, because its color is golden, it looks heavy on the palm and its taste is sweet",
      },
      {
        question: "Do you mix any preservatives in the flour?",
        answer:
          "No, it is hygeincally processed and packed, ensuring all food safety and quality assurance parametrs",
      },
      {
        question: "What is the nutritional value for both the Sharbati types?",
        answer:
          "SHARBATI ATTA<br /> SHARBATI MULTIGRAIN <br /> CHAPPATI FLOUR<br /> MULTI- CÉRÉALES FARINE<br /> Simulated English/French Panel. Use proper FDA vertical format, bilingual.<br /> Nutrition Facts / Valeur nutritive<br /> 151 servings per container/ portion par contenant                330 servings for 20 LB label<br /> Serving Size                                 1/2 cup (30g)<br /> Portion                                      1/2 tasse (30g)<br /> Amount per serving/ Teneur par portion<br />Calories/ Calories        110<br /><br /> % Daily Value*<br />% Valeur Quotidienne*<br />Total Fat/ Graisse Totale 1g        1%<br /> Saturated Fat/ Gras Saturés 0g        0% <br /> Trans Fat/ Gras Trans<br />Cholesterol/ Cholesterol 0mg        0%<br />Sodium/ Sodium 20mg        1%                                <br />Total Carbohydrate/ Glucides Totaux 21g        10%<br /> Dietary Fiber/ Fibres Alimentaires 3g        11%<br /> Total Sugars/ Sucres Totaux 1g<br /> Includes 0g Added Sugars/        0%<br /> Comprend Le Sucre 0g Ajouté        0%<br />Protein/Proteine 5g<br /><br /> Vitamin D/ Vitamine D 0mcg        0%<br />Calcium/ Calcium 40mg        4%<br />Iron/ Fer 1.0mg        6%<br /> Potassium/ Potassium 120mg        2%<br />",
      },
      {
        question: "Which wheat is better lokwan or sharbati?",
        answer:
          "Lokwan wheat has ample health benefits as it is rich in fibre and possesses anti-inflammatory properties. The wheat has good amount of vitamins, proteins and minerals. The wheat flour when consumed keeps you fuller for a longer time. It is best for rolling soft rotis and phulkas and can also be used in baking.",
      },
      {
        question: "Is sharbati Atta gluten free?",
        answer: "No, it is low in gluten",
      },
      {
        question: "Is Sharbati wheat hard or soft?",
        answer: "Soft",
      },
      {
        question: "What are the prices and sizes for Sharbati flour?",
        answer:
          "Sharbati Chapati Flour 10 LB, 20 lb, 4 lb, prices not in my scope.",
      },
    ],
  },
  {
    title: "Besan",
    faqs: [
      {
        question: "What is Besan?",
        answer:
          "Besan is gluten-free flour used in the preparation of several Indian sweet and savoury snacks including the world-famous laddoo, it is made from a type of ground chickpea called the gram chickpea. It is a staple ingredient in the cuisine of the Indian subcontinent, including in Indian, Bangladeshi, Burmese, Nepali, Pakistani, Sri Lankan and Caribbean cuisines.",
      },
      {
        question: "What is the nutritional value for Besan?",
        answer:
          "Chickpea flour (besan), 1 cup <br />Protein (g)        20.6 <br />Total lipid (fat) (g)        6.15 <br />Carbohydrate, by difference (g)        53.19 <br />Energy (kcal)        356.04 <br />Sugars, total (g)        9.98",
      },
      {
        question: "is this the flour for the facial masks?",
        answer: "Yes",
      },
      {
        question: "Is the product Gluten-Free?",
        answer: "Yes",
      },
      {
        question: "is this Besan organic?",
        answer: "No",
      },
      {
        question: "Is this roasted Besan flour?",
        answer: "No",
      },
      {
        question: "Is this peanut and tree nut-free?",
        answer: "Yes",
      },
      {
        question: "What is besan used in?",
        answer:
          "Besan has various nutritional and delicacious use, it can be used in making variety of sweets snacks, curies, batters , soups, thickeners. It is also used as medicenes and as facepack also, Here are some easy ways to add chickpea to your diet: <br /> Dhokla: Popular snack in India Dhokla is healthy, filling and tasty. The cooking procedure requires fermentation of the flour which actually enhances its nutritive value. Two medium pieces of steamed, home-made dhokla contain about 45 calories. Chila: The nutty flavour and taste of Besan or chickpea flour makes it a tasty breakfast option. Besan flour is naturally higher in protein than wheat-based flours. Make the batter for Besan pancake or chila with curd or milk; add vegetables and pan-grill with minimal oil. Have it with a glass of low fat milk or curd.  <br />Chaat: Chickpea chaat is easy to prepare. Take boiled chickpea; add raw, chopped onion & tomatoes. Sprinkle some black pepper, salt and squeeze fresh lime on top. It is the freshest & healthiest food you can snack on. A bowl of chickpea chaat contains approximately 120 calories.",
      },
      {
        question: "What are the health benefits of Besan?",
        answer:
          "The health benefits of gram flour/besan are as follows: <br /> 1. Helps Lower Cholesterol: Gram flour contains healthy unsaturated fats which help in lowering the cholesterol level of the body.<br /> 2. Controls Diabetes: Owing to its low glycemic index, it is a great food for diabetics. Use it in your rotis, paranthas as a replacement for flour. Glycemic index of chickpea is just 10.<br /> 3. Helps improve health of the heart: Besan has high soluble fiber content which is beneficial for the health of the heart. It is also endorsed by the Heart Care Foundation.<br /> 4. Healthy alternative for Gluten: Since besan does not contain gluten, it is a great substitute to wheat and other gluten containing grains, for those people who are allergic to gluten.<br /> 5. Helps getting rid of iron deficiency: Being rich in iron, consumption of besan on a daily basis can help your body recover from iron deficiencies like anaemia.<br /> 6. Useful in pregnancy: Gram flour is rich in folate, a vitamin which takes care of the foetus’ brain and spinal cord development and ensures a healthy well developed baby.<br /> 7. Helps during fatigue: Gram flour is a good source of the vitamin Thiamin which helps the body in converting food into energy.<br /> 8. Helps regulate mood and appetite: Besan is rich in vitamin B6. Vitamin B6 is an important component involved in the synthesis of the neurotransmitter serotonin. Serotonin plays an important in mood and appetite regulation.<br /> 9. Helps regulate blood pressure: The magnesium content of besan helps in maintaining vascular health and it also helps in the regulation of blood pressure.<br /> 10.Helps in strengthening of bones: The phosphorous in gram flour combines with calcium in the body to help in the formation of bones.<br /> ",
      },
      {
        question: "What are the prices and sizes for Besan?",
        answer: "2 lb, 4 lb, 10 lb",
      },
      {
        question: "Is besan is good for the skin?",
        answer:
          "Gram flour has certain properties that treat acne and has been used for this purpose in India for centuries. For one, the zinc in besan has been shown to fight the infections that cause your face to erupt with acne. Secondly, it also helps control excess sebum production and soothes inflamed skin. The best part about besan (gram flour) is that it works for all skin types, and that's what makes it the ideal cleanser.",
      },
    ],
  },
  {
    title: "Sona Masoori Rice",
    faqs: [
      {
        question: "What is sona masoori rice?",
        answer:
          "Sona Masuri is a lightweight and aromatic medium-grain rice which is the result of a cross combination of the rice varieties Sona and Mahsuri. It is grown largely in the Indian states of Andhra Pradesh, Telangana and Karnataka, and used primarily in South Indian cuisine, Sona Masoori is one of the best rice types which are popular grain in India and other parts of the globe also; it is a premium kind of rice. And, it is easiest to digest during any time and that is why it is recommended for any age of a person. Due to the high carbohydrate content in it, it provides ample energy. Sona Basmati rice is grown in the southern states of India. It is very strong rice and contains high fiber content. Besides, it has a good amount of starch and it’s required to rinse before cooking.",
      },
      {
        question: "What is the nutritional value of Sona Masoori Rice?",
        answer:
          "Sona Masoori rice is enriched with many vital nutrients. This rice variety has a low glycemic index and is also considered * as a suitable option for diabetic patients.",
      },
      {
        question:
          "What is the difference between raw rice and Sona Masoori rice?",
        answer:
          "Sona Masoori is commonly known as Par-Boiled rice (Half Boiled) or Puzhungal Arisi in Tamil or simply White rice. Since this rice variety is partially boiled in the husk, it is cooked faster compared to Raw Rice (Pacharisi in Tamil). These rice varieties are the most commonly used one to prepare plain white rice.",
      },
      {
        question: "What is the package weight in pounds?",
        answer: "4LBS & 10LBS & 20LBS",
      },
      {
        question: "What are the prices and sizes for Sona Masoori Rice?",
        answer:
          "Normally it is at very affordable price rice  & Average size 5.15mm to 5.30mm",
      },
      {
        question: "Is Sona Masoori rice good for weight loss?",
        answer: "This rice is low in calories. It is easily digestible",
      },
      {
        question: "Is Sona Masoori rice healthier than basmati rice?",
        answer:
          "Usage of sona masoori differs from basmati and cokking method also, so both cannnot be compared ",
      },
      {
        question: "Is Sona Masoori rice good?",
        answer:
          "Since Sona Masoori contains less starch than Basmati rice, it is easier to digest and considered in Southern India to be the healthier choice, Sona masonry rice is softer that other assortment white rice and has got a distinctive flavour and taste,It is easily digestible and good for weight loss. it has a low starch content, and being unpolished rice, it's useful for diets and healthy eating. It's used for such popular Indian dishes as biryani, idlis, just simple fried rice and sweet pongal.Best suited for weight loss.Help your body derive energy.Promotes proper functioning of your heart, brain, liver and kidneys.Contain protein.Fat free and sodium free.",
      },
    ],
  },
  {
    title: "Toor Dal",
    faqs: [
      {
        question: "Is chana dal and toor dal the same?",
        answer:
          "No, they are completely different types of dals. Toor Dal is commonly used in Indian dal preparations, but the same can't be said for Chana Dal. Chana Dal is mainly used for other delicacies like Puran Poli, Dal Vada, Sabzi preparations, etc. chana is slightly larger, thicker, bumpier. Toor dal is flatter, slightly smaller, more regular in shape. Toor dal is often seen in Indian groceries in either oily or “unoily” or “dry” varieties.It is beige in colour, with a yellow interior part. It is an important source of folic acid, proteins, and fiber. It is often prepared with onions, tomatoes and rich spices and served with rice/ roti",
      },
      {
        question: "What is toor dal called?",
        answer:
          "Toor Dal is one of the lentils that is widely used in India. It is also known as Pigeon Peas Lentils, Toovar dal, Tuvar Dal, Arhar Dal, Peeli Dal or Yellow Dal in various regions",
      },
      {
        question: "What is the nutritional value for Toor dal?",
        answer:
          "This is generic, as I don’t have comp data so cant recommend to mention laxmiToor Dal Nutrition Content <br /> Nutrient        Quantity Per 100 Gram of Toor Dal <br /> Polyunsaturated Fat        0.508g <br /> Carbohydrates        29.76g <br /> Sugar        1.21g <br /> Fibre        14.3g",
      },
      {
        question: "Is Tur dal a lentil?",
        answer:
          "Toor Dal is one of the lentils that is widely used in India. It is also known as Pigeon Peas Lentils, Toovar dal, Tuvar Dal, Arhar Dal, Peeli Dal or Yellow Dal in various regions.",
      },
      {
        question: "Does cooked toor dal contain protein?",
        answer:
          "Toor dal or pigeon pea is a perennial legume belonging to the Fabaceae family, which is also known as split pigeon pea, red gram, arhar dal or toor dal. Toor dal, a native crop of India is a chief accompaniment with roti or rice, it is vouched by nutritionists and health experts in unison as the complete food that should be added in the regular meal plan. It also a common food in Asia, Africa and America and cultivated since 3500 years ago. The seed pods look flat, sickle-shaped and grow to a length of 5-9cm, each pod comprising 2-9 seeds with a blend of colour ranging from white, cream, yellow, purple and black and this annually grown plant is drought-resistant. <br /><br />Toor dal is the primary source of protein for vegetarians that comes with a dense nutrient profile and a bowl of dal chawal is the best comforting food for the mind and body.<br /> <br />Nutritional Facts<br /><br />Toor dal or arhar dal is a rich source of protein, carbohydrates and fibre. This humble pulse facilitates to meet your daily demands of iron and calcium. Besides these, toor dal is an incredible source of folic acids which is essential for fetal growth and prevents the birth defects of the new-born. Being low on glycemic index toor dal is a good addition in diabetic’s meal plan. The richness of fibre and protein keeps you satiated, curbs hunger pangs, promote weight loss and reduces the cholesterol level. It also contains Vitamins C, E, K and B complex along with minerals magnesium, manganese, phosphorus, potassium, sodium, zinc. <br /><br />Health Benefits Of Toor Dal <br /><br />Promotes Iron Stores",
      },
      {
        question: "Is toor dal good for skin?",
        answer: "Yes",
      },
      {
        question: "What are the prices and sizes for Toor dal?",
        answer: "2lb, 4 lb, 8 lb, ",
      },
    ],
  },
  {
    title: "Chana Dal",
    faqs: [
      {
        question: "Is chana dal the same as chickpeas?",
        answer:
          "“Chana” is chickpeas, and “dal” is split lentils, peas, or chickpeas. Therefore, chana dal is split chickpeas in soup or curry form! For this dish, chickpeas are split and polished, resulting in a different texture and feel than whole chickpeas (while maintaining the same health benefits).",
      },
      {
        question: "Is chana dal the same as Gram Dal?",
        answer:
          "Chana Dal also known as the Split Bengal Gram is closely related to the chickpea family. It is yellow lentil, rounded on one side and flat on the other. Bengal gram is one of the earliest cultivated variety of legumes.",
      },
      {
        question: "What is the nutritional value for Chana dal?",
        answer:
          "Value per per serving        % Daily Values <br /> Protein        8.3 g        15% <br /> Carbohydrates        25.4 g        8% <br /> Fiber        6.4 g        26% <br /> Fat        9.7 g        15%",
      },
      {
        question: "Are these GMO-free?",
        answer: "No such data available so cant claim",
      },
      {
        question: "What are the prices and sizes for Chana dal?",
        answer: "2lb, 4lb, 8lb",
      },
      {
        question: "What are the benefits of chana dal?",
        answer:
          "Chana dal is rich in B-complex vitamins that play an important role in glucose metabolism and are vital for your body to remain active and energetic. It is also essential for the heart as it is rich in antioxidants and reduces inflammation keeping the organ healthy.",
      },
      {
        question: "How can I soften chana dal without a pressure cooker?",
        answer:
          "If time is not much of a concern, let your chane cook in a regular saucepan on stove. You have to remember to add four times water of the quantity of chana. Once the water comes to a boil, bring down the flame to low and let it simmer for 1 to 2 hours depending on the extent you want the chana to soften.",
      },
      {
        question: "Is it important to Soak chana dal?",
        answer:
          "It always helps to soak the lentils as they cook faster and taste better too. You may soak the chana dal for 1 hour in hot water so they cook faster.",
      },
      {
        question: "What are the prices and sizes for Chana dal?",
        answer: "Repeated ",
      },
    ],
  },
  {
    title: "Extra long basmati rice",
    faqs: [
      {
        question: "What is the nutritional value of Basmati rice?",
        answer:
          "Extra Long Grain Basmati Rice (1 cup) contains 148g total carbs, 146g net carbs, 1g fat, 13g protein, and 675 calories.",
      },
      {
        question: "What is the carb count on a serving of this rice?",
        answer:
          "Extra Long Grain Basmati Rice (1 cup) contains 148g total carbs, 146g net carbs, 1g fat, 13g protein, and 675 calories.",
      },
      {
        question: "Are these GMO-free?",
        answer: "Yes",
      },
      {
        question: "What are the prices and sizes for Extra long basmati rice?",
        answer: "varies , 2, 4 8, 10, 20 lbs",
      },
      {
        question: "Is this rice keto-friendly?",
        answer: "No",
      },
      {
        question: "What length is Laxi extra long basmati rice?",
        answer: "its average size 8.35 mm to 8.40mm",
      },
      {
        question: "Is extra long basmati rice is best for biryani?",
        answer:
          "The long grains and aromatic splendor of the BB Royal Biryani Basmati Rice are perfect for cooking various types of Pulao, Fried Rice and especially Biriyani. These extra-long grains will cook up as separate fluffy grains every time and are ideal for your one-pot dishes like biryanis and pulao.",
      },
      {
        question: "Is basmati rice better than regular rice?",
        answer:
          "It's recognized as a whole grain and contains about three times more fiber and significantly more protein than white rice, making it a more filling choice.",
      },
    ],
  },
  {
    title: "Ginger garlic paste",
    faqs: [
      {
        question: "What is the nutritional value of Ginger garlic paste?",
        answer:
          'Local Purchase of US, Don’t have the data     - use below link for generic data  <a href="https://fdc.nal.usda.gov/fdc-app.html#/food-details/451953/nutrients" target="_blank">https://fdc.nal.usda.gov/fdc-app.html#/food-details/451953/nutrients</a> Correlated to 100 gm',
      },
      {
        question: "What are the prices and sizes for Ginger garlic paste?",
        answer:
          "SKUs- 8 OZ, 24 oz	200 gm Pouch- Rs. 45/ Online data Sizes- Customer specific. Its vary industry to industry. No specific size for such product.",
      },
      {
        question: "Is readymade ginger garlic paste good for health?",
        answer:
          "Prevents cold and flu: Ginger garlic paste boosts immune system thereby helping your body combat the diseases. The anti-inflammatory, antifungal and antimicrobial properties of garlic protect you from the infectious disease.",
      },
      {
        question: "How long will this last in the fridge, open?",
        answer:
          "Store the paste in a glass bottle that is washed and thoroughly dried. It can last for up to five months in the refrigerator, and up to six months in the freezer, Make sure the lid is airtight. Salt and oil help make the ginger garlic paste last longer.",
      },
      {
        question: "What are the prices and sizes for Ginger garlic paste?",
        answer: "Repeated",
      },
      {
        question: "Is ginger paste healthy?",
        answer:
          "With its anti-inflammatory, antifungal and antimicrobial properties, garlic forms a triple protectant from infectious diseases. Ginger garlic paste is known to boost the immune system, thereby helping your body combat diseases, and increasing its speed of recovery",
      },
      {
        question: "How long does ginger garlic paste last in the fridge?",
        answer: "Repeated",
      },
      {
        question: "What is ginger garlic paste used for?",
        answer:
          "Ginger garlic paste is a key ingredient in many Indian dishes, from curries to biryanis and everything in between. It adds delicious, savory notes. It is often used in rice preparation slike biryanis,pulao and curries like chana masala, matar paneer, etc.",
      },
      {
        question: "Can I put ginger garlic paste after cooking?",
        answer:
          "Depends on usage and the product made, ideally it shuld be added to curries. Gravies etc while roasting spices",
      },
    ],
  },
  {
    title: "Spices - turmeric",
    faqs: [
      {
        question: "What is another name for turmeric?",
        answer:
          "Turmeric is also known as Curcuma, Curcumin, Halada, Haldi, Haridra, Indian Saffron, Nisha, Pian Jiang Huang, Rajani, Safran Bourbon, Safran de Batallita, Safran des Indes, Turmeric Root, and Yu Jin.",
      },
      {
        question: "What is the flavor of turmeric spice?",
        answer:
          "Flavor-wise, it is overwhelmingly earthy and bitter, almost musky, with a bit of peppery spice.",
      },
      {
        question: "Why is turmeric bad for you?",
        answer:
          "Taking turmeric in large doses has potential risks: Mild side effects include upset stomach, acid reflux, diarrhea, dizziness and headaches. Consuming large doses of turmeric supplements can significantly increase the levels of urinary oxalate, increasing the risk of kidney stone formation.",
      },
      {
        question: "What foods taste best with turmeric?",
        answer:
          "Turmeric's slightly warm and peppery flavor works especially well with cauliflower, potatoes, and root vegetables. Rice, quinoa, bulgur and couscous all mingle well with turmeric.",
      },
      {
        question: "What are the benefits of turmeric?",
        answer:
          "In addition to antioxidant effects, turmeric has also been shown to lower cholesterol and triglycerides in people at risk of heart disease, and may improve blood pressure. Antioxidants in turmeric may also reduce the risk of cataracts, glaucoma and macular degeneration.",
      },
      {
        question: "What is turmeric commonly used for?",
        answer:
          "In India, it was traditionally used for disorders of the skin, upper respiratory tract, joints, and digestive system. Today, turmeric is promoted as a dietary supplement for a variety of conditions, including arthritis, digestive disorders, respiratory infections, allergies, liver disease, depression, and many others.",
      },
      {
        question: "Is turmeric 100% pure?",
        answer:
          "One of the easiest ways to check adulteration is to add a teaspoon of turmeric to a glass of warm water. Do not stir it and leave it still for a while. Check after about 20 minutes. If the powder settles down at the bottom of the glass with clear water above, the turmeric is pure.",
      },
      {
        question: "Can I take turmeric during fast?",
        answer:
          "Turmeric can certainly be useful for your wellness or weight loss journey. Whether you use it during your fast or during your eating window is really up to you.",
      },
      // {
      //   question: "What are the prices and sizes for turmeric?",
      //   answer: "",
      // },
      {
        question: "What is the local name of turmeric?",
        answer:
          "In North India, turmeric is commonly called “haldi,” a word derived from the Sanskrit word haridra, and in the south it is called “manjal,” a word that is frequently used in ancient Tamil literature.",
      },
      {
        question: "How many varieties of turmeric are there?",
        answer:
          "India is also home to more than 30 different types of turmeric varieties all unique to their respective regions.",
      },
      {
        question: "Is turmeric a good vitamin to take?",
        answer:
          "Turmeric is rich in vitamin C, vitamin B6, and other antioxidants that reduce the risk of serious health conditions like heart disease and diabetes. In addition, it's an excellent source of: Manganese, Iron.",
      },
      {
        question: "Who should not use turmeric?",
        answer:
          "People who should not take turmeric include those with gallbladder problems, bleeding disorders, diabetes, gastroesophageal reflux disease (GERD), infertility, iron deficiency, liver disease, hormone-sensitive conditions and arrhythmia. Pregnant women and those who are going to undergo surgery should not use turmeric.",
      },
    ],
  },
  {
    title: "Red chilli",
    faqs: [
      {
        question: "What is red chili powder & where does it originate from?",
        answer:
          "Red chili powder is basically is a spice blend consisting of one or two types of dried red chillies that are ground and pulverized into fine powder.",
      },
      // {
      //   question: "What is the nutritional value of red chili powder?",
      //   answer: "",
      // },
      // {
      //   question: "Is red chili powder the same as chili powder?",
      //   answer: "",
      // },
      {
        question: "What are the health benefits of red chili powder?",
        answer:
          "Helps In Digestion. Maintains Blood Pressure Levels. Anti-inflammatory. Helps in Weight Loss. Improves Cognitive Function. Improves Heart Health. Helps in Nasal Congestion. Builds Immunity And Fights Diseases.",
      },
      // {
      //   question: "What are the prices and sizes of red chili powder?",
      //   answer: "",
      // },
    ],
  },
  // {
  //   title: "Coriander powder",
  //   faqs: [
  // {
  //   question: "What is coriander powder?",
  //   answer: "",
  // },
  // {
  //   question: "What is the nutritional value of coriander powder?",
  //   answer: "",
  // },
  // {
  //   question: "What can I replace coriander powder with?",
  //   answer: "",
  // },
  // {
  //   question: "Is coriander powder the same as curry powder?",
  //   answer: "",
  // },
  // {
  //   question: "How do you use coriander powder in food?",
  //   answer: "",
  // },
  // {
  //   question: "What is coriander powder used for?",
  //   answer: "",
  // },
  // {
  //   question: "What are the health benefits of coriander?",
  //   answer: "",
  // },
  // {
  //   question: "What are the prices and sizes of coriander powder?",
  //   answer: "",
  // },
  //   ],
  // },
  // {
  //   title: "Cinnamon",
  //   faqs: [
  // {
  //   question: "What is cinnamon?",
  //   answer: "",
  // },
  // {
  //   question: "What are the prices and sizes of cinnamon?",
  //   answer: "",
  // },
  // {
  //   question: "What is the nutritional value of cinnamon?",
  //   answer: "",
  // },
  // {
  //   question: "What are the main health benefits of cinnamon?",
  //   answer: "",
  // },
  // {
  //   question: "What are the prices and sizes of cinnamon?",
  //   answer: "",
  // },
  // {
  //   question: "What are the benefits for women?",
  //   answer: "",
  // },
  // {
  //   question: "What are the most common uses for cinnamon?",
  //   answer: "",
  // },
  // {
  //   question: "Does cinnamon help you lose weight?",
  //   answer: "",
  // },
  //   ],
  // },
  {
    title: "Black pepper",
    faqs: [
      // {
      //   question: "What are the prices and sizes of Black pepper?",
      //   answer: "",
      // },
      // {
      //   question: "What is the nutritional value of Black pepper?",
      //   answer: "",
      // },
      {
        question: "Is it good to eat black pepper every day?",
        answer:
          "There is no harm including it in your diet, but make sure to consume it in moderation. Do not have more than 1-2 teaspoon of black pepper every day. Having it in excess can have multiple side effects.",
      },
      {
        question: "Can you eat black pepper raw?",
        answer:
          "Black pepper helps in good digestion and when it is consumed raw, hydrochloric acid is released by the stomach and helps in breaking down the proteins. It is considered safe for human consumption in the typical amounts used in food and cooking.",
      },
      {
        question: "Is Black pepper good for skin?",
        answer:
          "Pepper's antibacterial and anti-inflammatory properties help cure skin infections and acne. Apart from adding it to your diet, try including it in your face scrubs. It exfoliates dead skin and stimulates blood circulation causing more oxygen to flow to your face.",
      },
      {
        question: "Is black pepper good for weight loss?",
        answer:
          "Black pepper can help you shed weight. It is loaded with Vitamins A, C, and K, minerals, healthy fatty acids and works as a natural metabolic booster which makes it a storehouse of uncountable health benefits, including weight loss. It also contains piperine.",
      },
      // {
      //   question: "Are there any side effects of having Black pepper?",
      //   answer: "",
      // },
      // {
      //   question: "Is a lot of black pepper bad for you?",
      //   answer: "",
      // },
    ],
  },
  {
    title: "Lentils",
    faqs: [
      // {
      //   question: "What is the nutritional value of Lentils?",
      //   answer: "",
      // },
      // {
      //   question: "What are the prices and sizes of Lentils?",
      //   answer: "",
      // },
      {
        question: "Are lentils Keto-friendly?",
        answer:
          "Despite being rich in fiber, lentils contain a high number of total and net carbs, making them difficult to fit into a keto diet. While those following a strict keto diet should avoid lentils altogether, others may occasionally include small portions of these nutrient-rich legumes.",
      },
      {
        question: "Are lentils protein or carbs?",
        answer:
          "Lentils are high in fibre, and complex carbohydrates, while low in fat and calories. Their high protein content makes lentils a perfect option for those looking to boost their protein intake.",
      },
      {
        question: "Are lentils good for weight loss?",
        answer:
          "Eating more lentils may help people maintain a healthy weight or lose weight.",
      },
      {
        question: "Are lentils good for skin?",
        answer:
          "The nutrients present in red lentils makes it one of the best natural exfoliator that helps in getting rid of dead skin cells, leaving the skin smooth and dirt free. It helps in fighting acne and blackheads too.",
      },
      {
        question: "Can diabetics eat lentils?",
        answer:
          "Adding lentils offers substantial benefit to people with diabetes. Regularly eating lentils may help with the management and prevention of diabetes.",
      },
      {
        question: "How many lentils should I eat a day?",
        answer:
          "For good health, women should strive for an intake of at least 21 to 25 grams of fiber a day, while men should aim for a daily amount of 30 to 38 grams.",
      },
    ],
  },
  {
    title: "Masoor Dal",
    faqs: [
      // {
      //   question: "Is this process with wheat or soy",
      //   answer: "",
      // },
      // {
      //   question: "Is the bag resealable?",
      //   answer: "",
      // },
      // {
      //   question: "What is the nutritional value of cinnamon?",
      //   answer: "",
      // },
      // {
      //   question: "Has the husk/ skin been removed?",
      //   answer: "",
      // },
      // {
      //   question: "What are the prices and sizes of Lentils?",
      //   answer: "",
      // },
      {
        question: "Is masoor dal the same as red lentils?",
        answer:
          "Masoor dal is the same thing as red lentils, which are popular and easy to find at many grocery stores in the US.",
      },
      {
        question: "What is another name for masoor dal?",
        answer: "Masoor Dal is also known as Red Lentil.",
      },
      {
        question: "Is masoor dal high in protein?",
        answer:
          "Masoor Dal has high nutritional value as it is a rich source of protein, fiber and minerals and has low-fat content.",
      },
    ],
  },
];
