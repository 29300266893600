import React, { Suspense } from "react";

import { Switch, Route, Navigate, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Default Layout
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

//Routes go here
import Spinner from "./images/loader.gif";

//mostly common stlyes & each component css here
import "./App.scss";
import "./Appnew.css";
import "swiper/swiper.less";
import Faqpage from "./container/Faqpage/Faqpage";
import OurProductsNew from "./container/OurProductsNew/OurProductsNew";
import LandingPage from "./container/LandingPage/LandingPage";
import LandingThankyou from "./container/LandingThankyou/LandingThankyou";
import TermsAndConditions from "./container/TermsAndConditions/TermsAndConditions";
import ContestFloat from "./componentsnew/ContestFloat/ContestFloat";
import Banner from "./container/Diwalipage/Banner/Banner";
import { DiwaliForm } from "./container/Diwalipage/DiwaliForm/DiwaliForm";
import {
  diwali,
  diwaliabout,
  diwaliform,
  diwaligallery,
  diwalitermscondition,
  diwalithankyou,
  diwaliwinner,
  megawinners,
  termsandconditions,
  winners,
} from "./helpers/constant-words";
import DiwaliAbout from "./container/Diwalipage/DiwaliAbout/DiwaliAbout";
import DiwaliGallery from "./container/Diwalipage/DiwaliGallery/DiwaliGallery";
import DiwaliThankyou from "./container/Diwalipage/DiwaliThankyou/DiwaliThankyou";
import DiwaliTermsAndConditions from "./container/Diwalipage/DiwaliTermsAndConditions/DiwaliTermsAndConditions";
import Winner from "./container/Diwalipage/Winners/Winners";
import { useHistory } from "react-router-dom";
import MegaWinner from "./container/Diwalipage/MegaWinner/MegaWinner";
//Code splitting for performance
const Home = React.lazy(() => import("./pages/Home"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const OurStory = React.lazy(() => import("./pages/OurStory"));
const OurProducts = React.lazy(() => import("./pages/OurProducts"));
const OurKitchen = React.lazy(() => import("./pages/OurKitchen"));
const OurStoreLocator = React.lazy(() => import("./pages/OurStoreLocator"));
const Media = React.lazy(() => import("./pages/Media"));
const Contact = React.lazy(() => import("./pages/Contact"));
// const Blog = React.lazy(() => import("./pages/Blog"));
// const BlogCardDetails = React.lazy(() =>
// import("./components/BlogCardDetails")
// );

// ----------------vijay imports start
const Homepage = React.lazy(() => import("./container/Homepage/Homepage"));
const HomepageV2 = React.lazy(() => import("./container/Homepage/HomepageV2"));
const BlogsNew = React.lazy(() => import("./container/BlogsNew/BlogsNew"));
const BlogFullDetail = React.lazy(() =>
  import("./container/BlogFullDetail/BlogFullDetail")
);
// ----------------end of vijay imports start

//Main Component entry for routes
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Suspense
        fallback={
          <div className="Spinner">
            <img src={Spinner} alt="spinner" loading="lazy" />
          </div>
        }
      >
        <NavigationBar />
        <ContestFloat />
        {/* <ToastContainer> */}
        {/* <ToastContainer> */}
        <Switch>
          <Route exact path="/home" component={Home}></Route>
          <Route exact path="/" component={Homepage}></Route>
          <Route exact path="/home-v2" component={HomepageV2}></Route>
          <Route exact path="/story" component={OurStory}></Route>
          <Route exact path="/products" component={OurProducts}></Route>
          <Route exact path="/products-new" component={OurProductsNew}></Route>
          <Route exact path="/kitchen" component={OurKitchen}></Route>
          <Route
            exact
            path="/store-locator"
            component={OurStoreLocator}
          ></Route>
          <Route exact path="/media" component={Media}></Route>
          <Route exact path="/blogs" component={BlogsNew}></Route>
          <Route exact path="/blogs/:title" component={BlogFullDetail}></Route>
          {/* <Route exact path="/blogsold" component={Blog}></Route> */}
          {/* <Route exact path="/blogsold/:id" component={BlogCardDetails}></Route> */}
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/faq" component={Faqpage}></Route>
          <Route
            exact
            path="/50-years-contest"
            // component={LandingPage}
          >
            <Redirect to="/" />
          </Route>
          <Route
            exact
            path="/50-years-contest/thankyou"
            // component={LandingThankyou}
          >
            <Redirect to="/" />
          </Route>
          <Route
            exact
            path={termsandconditions}
            component={TermsAndConditions}
          ></Route>
          <Route
            exact
            path={diwali}
            // component={Banner}
          >
            <Redirect to="/" />
          </Route>
          <Route exact path={diwaliform}>
            <Redirect to={diwaliwinner} />
          </Route>
          <Route exact path={diwaliabout} component={DiwaliAbout}></Route>
          <Route exact path={diwaligallery} component={DiwaliGallery}></Route>
          <Route exact path={diwalithankyou} component={DiwaliThankyou}></Route>
          {/* <Route exact path={winners} component={Winner}></Route> */}
          <Route
            exact
            path={winners}
            // component={MegaWinner}
          >
            <Redirect to="/" />
          </Route>
          <Route
            exact
            path={diwalitermscondition}
            component={DiwaliTermsAndConditions}
          ></Route>
          <Route exact path="/404" component={NotFound}></Route>
          <Route path="*">
            <Redirect to="/404" />
            <NotFound />
          </Route>
        </Switch>
        {/* </ToastContainer> */}
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;

// https://laxmihos.com/api/homepage/banners done
// https://laxmihos.com/api/homepage/products done
// https://laxmihos.com/api/homepage/laxmi-kitchens done
// https://laxmihos.com/api/homepage/blogs done
// https://laxmihos.com/api/homepage/laxmiworld empty array

// https://laxmihos.com/api/blogs empty
// https://laxmihos.com/api/blogs/banners img dont work fixing

// https://laxmihos.com/api/laxmis-kitchen/recipes done search recipe text
// https://laxmihos.com/api/laxmis-kitchen/banners done

// https://laxmihos.com/api/contactus/banners done
// https://laxmihos.com/api/contactus/details changes need from api

// https://laxmihos.com/api/media/banners done
// https://laxmihos.com/api/media/videos done need change of size

// https://laxmihos.com/api/ourstory/welcome done
// https://laxmihos.com/api/ourstory/visionmission done
// https://laxmihos.com/api/ourstory/milestones only TITLE Milestones not there
// https://laxmihos.com/api/ourstory/banners done

// https://laxmihos.com/api/product/products working
