import React, { useState, useEffect } from "react";
import "./diwaliform.scss";
import Diwaliheader from "../DiwaliHeader/Diwaliheader";
import MetaDecorator from "../../../componentsnew/MetaDecorator/MetaDecorator";
import {
  dropDownArrow,
  formbackgorund,
  forminside,
  tooltip,
} from "../../../images";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../useWindowDimensions";

import config from "../../../config/config";
import Joi from "joi";
import http from "../../../helpers/http";
import helper from "../../../helpers/helper";
import GoToTop from "../../../components/GoToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  diwalitermscondition,
  diwalithankyou,
} from "../../../helpers/constant-words";

const body = document.querySelector("body");
export const DiwaliForm = (props) => {
  const { width } = useWindowDimensions();
  useEffect(() => {
    document.querySelector(".px-0.MainNavBar.shadow-lg").style.display = "none";
    document.querySelector(".CyanBg.MainFooter").style.display = "none";
    if (body) {
      body.style.height = "auto";
    }
  });

  const [values, setValues] = useState({
    gender: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    age: "",
    coupon_product: "",
    is_samosa_seen: "",
    is_veg_seen: "",
    code: "",
    state: "",
    city: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isTermsAccept, setIsTermsAccept] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  let base_url = config.api_url + "/forms/diwali-bonanza";

  let schemaObj = {
    first_name: Joi.string().trim().required().label("First name"),
    last_name: Joi.string().trim().required().label("Last name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Phone"),
    gender: Joi.string().trim().required().label("Gender"),
    age: Joi.number().required().label("Age"),
    coupon_product: Joi.string().required().label("Question"),
    is_samosa_seen: Joi.string().required().label("Question"),
    is_veg_seen: Joi.string().required().label("Question"),
    code: Joi.string().required().label("Coupon code"),
    zip: Joi.number().required().label("Zip Code"),
    state: Joi.string().required().label("State"),
    // city: Joi.string().required().label("City"),
  };
  if (cities.length > 0) {
    schemaObj.city = Joi.string().required().label("City");
  }

  const schema = Joi.object(schemaObj).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (!isTermsAccept) {
      errors["term"] = "REQUIRED";
    }

    // validating unique email
    if (data.code) {
      const result = await helper.validateCode(data.code);

      if (result === false) {
        errors["code"] = `The code is invalid/already in use`;
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    return false;
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);
    // let formData = new FormData();
    // for (const [key, data] of Object.entries(values)) {
    //   if (key == "phone") {
    //     formData.append(key, "+1" + data || "");
    //   } else {
    //     formData.append(key, data || "");
    //   }
    // }

    const { data } = await http.post(base_url, {
      ...values,
      phone: "+1" + values.phone,
    });

    if (data) {
      // redirect
      // navigate("/thank-you");
      props.history.push(diwalithankyou);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const handleStateChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });

    fetchCities(e.target.value);
  };

  const fetchStates = async () => {
    const { data } = await http.get(
      `${config.api_url}/general/states/United States`
    );
    console.log(data);
    if (data) {
      setStates(data);
    }
  };

  const fetchCities = async (state_name) => {
    const { data } = await http.get(
      `${config.api_url}/general/cities/${state_name}`
    );
    console.log(data);
    if (data) {
      setCities(data);
    }
  };

  useEffect(() => {
    AOS.init();
    fetchStates();
  }, []);

  return (
    <>
      <MetaDecorator
        metaTitle="Laxmi (HOS) - Enter Your Code:  Golden Jubilee Bonanza"
        metaDesc="Enter your Coupon Code and fill in the details for a chance to win prizes upto 20,000 USD in Laxmi's Golden Jubilee Bonanza."
        canonicalLink={window.location.href}
      />
      <Diwaliheader />
      <section className="form_sec">
        <div
          className="form_background"
          data-aos="fade"
          data-aos-duration="2000"
        >
          {/* <img
            className="background_img"
            src={formbackgorund}
            alt="background_image"
          /> */}
          <div className="background_img">
            <div className="my_container">
              <div className="form_wrapper">
                <h1 className="form_heading">ENTER YOUR CODE</h1>
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="form_flex">
                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          First Name<span className="mandatory">*</span>
                        </p>
                        <input
                          type="text"
                          className="form_input"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.first_name}</p>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          Last Name<span className="mandatory">*</span>
                        </p>
                        <input
                          type="text"
                          className="form_input"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.last_name}</p>
                      </div>

                      {/* contact no field */}
                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          Contact No<span className="mandatory">*</span>
                        </p>
                        <div className="mobile_no_wrapper">
                          <div className="country_code_select_wrapper">
                            <select className="input_field">
                              <option value="+1">+1</option>
                            </select>
                            <img
                              src={dropDownArrow}
                              alt=""
                              className="dropDownArrow"
                            />
                          </div>
                          <input
                            type="tel"
                            className="form_input"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </div>
                        <p className="error_message">{formError.phone}</p>
                      </div>

                      {/* Gender dropdown */}
                      <div className="form_field_wrapper form_field_customwidth dropdown_field">
                        <p className="form_label">
                          Gender<span className="mandatory">*</span>
                        </p>

                        <div className="dropdown_wrapper">
                          <select
                            id="genders"
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                          >
                            <option value="" selected disabled>
                              Select any
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Rather not to say">
                              Rather not to say
                            </option>
                          </select>
                          <img
                            src={dropDownArrow}
                            alt=""
                            className="dropDownArrow2"
                          />
                        </div>
                        <p className="error_message">{formError.gender}</p>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          Email ID<span className="mandatory">*</span>
                        </p>
                        <input
                          type="text"
                          className="form_input"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.email}</p>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          Age<span className="mandatory">*</span>
                        </p>
                        <input
                          type="tel"
                          className="form_input"
                          name="age"
                          value={values.age}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.age}</p>
                      </div>

                      {/* State dropdown box */}
                      <div className="form_field_wrapper form_field_customwidth dropdown_field">
                        <p className="form_label">
                          State
                          <span className="mandatory">*</span>
                        </p>
                        <div className="dropdown_wrapper">
                          <select
                            id="state"
                            name="state"
                            value={values.state}
                            onChange={handleStateChange}
                          >
                            <option value="" selected disabled>
                              Select any
                            </option>
                            {states.map((state, i) => {
                              return (
                                <option value={state.name} key={i}>
                                  {state.name}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            src={dropDownArrow}
                            alt=""
                            className="dropDownArrow"
                          />
                        </div>
                        <p className="error_message">{formError.state}</p>
                      </div>

                      {/* City dropdown box */}
                      <div className="form_field_wrapper form_field_customwidth dropdown_field">
                        <p className="form_label">
                          City
                          <span className="mandatory">*</span>
                        </p>
                        <div className="dropdown_wrapper">
                          <select
                            id="city"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                          >
                            <option value="" selected disabled>
                              Select any
                            </option>
                            {cities.map((city, i) => {
                              return (
                                <option value={city.name} key={i}>
                                  {city.name}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            src={dropDownArrow}
                            alt=""
                            className="dropDownArrow"
                          />
                        </div>
                        <p className="error_message">{formError.city}</p>
                      </div>

                      {/* Zipcode box */}
                      <div className="form_field_wrapper form_field_customwidth zipcode_box">
                        <p className="form_label">
                          Zip Code
                          <span className="mandatory">*</span>
                        </p>
                        <input
                          type="tel"
                          className="form_input"
                          name="zip"
                          value={values.zip}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.zip}</p>
                      </div>

                      {/* Dropdown box */}
                      <div className="form_field_wrapper form_field_customwidth dropdown_field">
                        <p className="form_label">
                          In which Laxmi Product have you received this coupon?
                          <span className="mandatory">*</span>
                        </p>
                        <div className="dropdown_wrapper">
                          <select
                            id="meun-items"
                            name="coupon_product"
                            value={values.coupon_product}
                            onChange={handleChange}
                          >
                            <option value="" selected disabled>
                              Select any
                            </option>
                            <option value="Laxmi Sharbati Atta">
                              Laxmi Sharbati Atta
                            </option>
                            <option value="Extra Long Basmati Rice">
                              Extra Long Basmati Rice
                            </option>
                            <option value="Laxmi Sona Masoori Rice">
                              Laxmi Sona Masoori Rice
                            </option>
                          </select>
                          <img
                            src={dropDownArrow}
                            alt=""
                            className="dropDownArrow"
                          />
                        </div>
                        <p className="error_message">
                          {formError.coupon_product}
                        </p>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          Have you seen Laxmi Samosa in the stores near you?
                          <span className="mandatory">*</span>
                        </p>
                        <div className="radio_wrapper">
                          <input
                            type="radio"
                            id="yes"
                            name="is_samosa_seen"
                            value="yes"
                            checked={values.is_samosa_seen == "yes"}
                            onChange={handleChange}
                          />
                          <label for="yes">Yes</label>
                          <input
                            type="radio"
                            id="no"
                            name="is_samosa_seen"
                            value="no"
                            checked={values.is_samosa_seen == "no"}
                            onChange={handleChange}
                          />
                          <label for="no">No</label>
                        </div>
                        <p className="error_message">
                          {formError.is_samosa_seen}
                        </p>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="form_label">
                          Have you seen the Laxmi Frozen Vegetable range in the
                          stores near you?<span className="mandatory">*</span>
                        </p>
                        <div className="radio_wrapper">
                          <input
                            type="radio"
                            id="frozenyes"
                            name="is_veg_seen"
                            value="yes"
                            checked={values.is_veg_seen == "yes"}
                            onChange={handleChange}
                          />
                          <label for="frozenyes">Yes</label>
                          <input
                            type="radio"
                            id="frozenno"
                            name="is_veg_seen"
                            value="no"
                            checked={values.is_veg_seen == "no"}
                            onChange={handleChange}
                          />
                          <label for="frozenno">No</label>
                        </div>
                        <p className="error_message">{formError.is_veg_seen}</p>
                      </div>

                      {/* Coupon code box */}
                      <div className="form_field_wrapper form_field_customwidth field_wtih_tooltip">
                        <p className="form_label">
                          Enter Coupon Code<span className="mandatory">*</span>
                        </p>
                        <input
                          type="text"
                          className="form_input coupon_box"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.code}</p>
                        <div class="click-to-top">
                          <img
                            className="tooltip_icon"
                            src={tooltip}
                            alt="tooltip icon"
                          />
                          <span>
                            Scratch the card found in your Laxmi product bag &
                            enter the code here.
                          </span>
                        </div>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth field_wtih_tooltip coupon_dummy">
                        <p className="form_label">
                          Enter Coupon Code Dummy
                          <span className="mandatory">*</span>
                        </p>
                        {/* <input
                          type="text"
                          className="form_input coupon_box"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.code}</p>
                        <div class="click-to-top">
                          <img
                            className="tooltip_icon"
                            src={tooltip}
                            alt="tooltip icon"
                          />
                          <span>
                            Scratch the card found in your Laxmi product bag &
                            enter the code here.
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div className="checkbox_wrapper">
                      <div class="checkbox-div2">
                        <input
                          type="checkbox"
                          id="checkbox2"
                          name="term"
                          checked={isTermsAccept ? true : false}
                          onChange={() => setIsTermsAccept(!isTermsAccept)}
                        />
                        <label for="checkbox2" className="section_desc">
                          By clicking the submit button below, I hereby agree to
                          the following{" "}
                          <u>
                            <a
                              className="checkbox_link"
                              href={diwalitermscondition}
                              target="_blank"
                            >
                              terms and conditions
                            </a>
                          </u>
                          , governing my use of data on Laxmi's Diwali Golden
                          Jubliee Bonanza contest landing page.
                        </label>
                        <p className="term_error">{formError.term}</p>
                      </div>
                    </div>
                    <button disabled type="submit" className="submit_btn">
                      {loading ? "LOADING.." : "SUBMIT"}
                    </button>
                    <p className="form_label close_message">
                      Contest has closed, please come back to check the winners
                      on the 31st of December.
                    </p>
                  </fieldset>
                </form>
              </div>
              {/* <img
              className="diwali_offer_image"
              src={forminside}
              alt="diwali offer img"
            /> */}
            </div>
            {width < 600 ? (
              <img
                className="mobile_gift_img"
                src={forminside}
                alt="gift image"
              />
            ) : null}
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  );
};
