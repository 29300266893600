import React, { useState, useEffect } from "react";
import {
  diwalileftarrow,
  diwaliplayicon,
  diwalirightarrow,
} from "../../../images";
import { diwaligalleryData } from "../DiwaliData";
import Diwaliheader from "../DiwaliHeader/Diwaliheader";
import "./diwaligallery.scss";
import useWindowDimensions from "../../../useWindowDimensions";
import FsLightbox from "fslightbox-react";
//swiper imports
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import AOS from "aos";
import "aos/dist/aos.css";

const DiwaliGallery = () => {
  const { width } = useWindowDimensions();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleImageWrapperClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const diwaliGalleryList = diwaligalleryData.map(
    ({ ytLink, img, Name }, i) => (
      <div className="swiper-slide" key={i}>
        <div
          className="gallery_box"
          onClick={() => handleImageWrapperClick(ytLink)}
        >
          <figure className="gallery_img_wrapper">
            <img
              src={img}
              alt="gallery image"
              className="gallery_img"
              loading="lazy"
            />
            <img
              width={144}
              src={diwaliplayicon}
              alt="play icon"
              className="diwaliplay"
            />
          </figure>

          <p className="gallery_text">{Name}</p>
        </div>
      </div>
    )
  );

  useEffect(() => {
    var diwali_gallery_swiper = new Swiper(
      ".swiper-container.diwali_gallery_swiper",
      {
        spaceBetween: 70,
        navigation: {
          prevEl: ".gallery_swiper_wrapper .left_arrow",
          nextEl: ".gallery_swiper_wrapper .right_arrow",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            slidesPerColumn: 3,
            slidesPerColumnFill: "row",
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 2.3,
          },
          1194: {
            slidesPerView: 3,
          },
          2560: {
            slidesPerView: 3.5,
          },
        },
      }
    );
  });

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Diwaliheader />
      <section className="diwali_gallery_sec">
        <div
          className="gallery_background"
          data-aos="fade"
          data-aos-duration="2000"
        >
          <section className="gallery_swiper_sec">
            <div className={width < 2000 ? "my_container" : null}>
              <div className="text_container">
                <h3 className="gallery_heading">GALLERY</h3>
              </div>
              <div className="gallery_swiper_wrapper">
                <div className="swiper-container diwali_gallery_swiper">
                  <div className="swiper-wrapper">{diwaliGalleryList}</div>
                </div>
                {diwaligalleryData.length > 3 
                // || width <= 1193 
                ? (
                  <div className="navigation_wrapper">
                    <img
                      src={diwalileftarrow}
                      alt=""
                      className="left_arrow"
                      loading="lazy"
                    />
                    <img
                      src={diwalirightarrow}
                      alt=""
                      className="right_arrow"
                      loading="lazy"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </section>
          <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
        </div>
      </section>
    </>
  );
};

export default DiwaliGallery;
