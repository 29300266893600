import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { contestbackground, contestbackgroundmob } from "../../images";
import "./contestfloat.scss";
import { useWindowSize } from "react-use";
import {
  diwali,
  diwaliabout,
  diwaliform,
  diwaligallery,
  diwalitermscondition,
  diwalithankyou,
  diwaliwinner,
  megawinners,
} from "../../helpers/constant-words";

const ContestFloat = () => {
  const { width: windowWidth } = useWindowSize();
  const [visible, setVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    console.log(location);
    if (
      location.pathname === diwali ||
      location.pathname === diwaliwinner ||
      location.pathname === diwaliabout ||
      location.pathname === diwaliform ||
      location.pathname === diwaligallery ||
      location.pathname === diwalithankyou ||
      location.pathname === diwalitermscondition ||
      location.pathname === megawinners
    ) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [location]);
  return (
    <>
      {/* {windowWidth >= 768 ? (
        <div
          className="contest_wrapper"
          style={{ display: visible ? "block" : "none" }}
        > */}
          {/* <Link to={diwaliwinner}>
            <img
              className="background_img_for_contest"
              src={contestbackground}
            /> */}
            {/* <div className="contest_test_on_image">
              Laxmi 50 Years Contest,
              <br />
              Participate Now!
            </div> */}
            {/* <div className="contest_test_on_image">#GoForGold</div>
          </Link> */}
        {/* </div>
      ) : ( */}
        {/* <div
          className="contest_wrapper_mobile"
          style={{ display: visible ? "block" : "none" }}
        >
          <Link to={diwaliwinner}>
            <img
              className="background_img_for_contest_mobile"
              src={contestbackgroundmob}
            /> */}
            {/* <div className="contest_test_on_image_mobile">
              Laxmi 50 Years Contest,
              <br />
              Participate Now!
            </div> */}
            {/* <div className="contest_test_on_image_mobile">#GoForGold</div>
          </Link>
        </div>
      )} */}
    </>
  );
};

export default ContestFloat;
