import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MetaDecorator from "../../../componentsnew/MetaDecorator/MetaDecorator";
import { diwaliform } from "../../../helpers/constant-words";
import {
  aboutback,
  aboutbackmob,
  aboutbacknew,
  aboutbacksm,
  shilpaimage,
} from "../../../images";
import useWindowDimensions from "../../../useWindowDimensions";
import Diwaliheader from "../DiwaliHeader/Diwaliheader";
import "./diwaliabout.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const DiwaliAbout = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <MetaDecorator
        metaTitle="Laxmi (HOS) - About The Golden Jubilee Bonanza"
        metaDesc="The Golden Jubilee Bonanza is an endeavor to rejoice this festive season on this joyous occasion with a lot of exciting giveaways."
        canonicalLink={window.location.href}
      />
      <Diwaliheader />
      <section className="diwali_about_sec">
        <div
          className="background_img"
          data-aos="fade"
          data-aos-duration="2000"
        >
          <img
            className="about_back"
            src={
              width > 600
                ? aboutbacknew
                : null || width > 370
                ? aboutbackmob
                : null || width < 370
                ? aboutbacksm
                : null
            }
          />
          <div className={width < 2000 ? "my_container" : null}>
            <div className="text_container">
              <h1 className="about_heading">ABOUT</h1>
              <p className="about_desc">
                Laxmi is proudly celebrating its legacy of 50 years. Being your
                #1 choice to recreate your favorite desi foods away from home,
                our journey of 50 glorious years wouldn’t have been possible
                without you. The Golden Jubilee Bonanza is an endeavor to
                rejoice this festive season on this joyous occasion with a lot
                of exciting giveaways. It is a magnificent sweepstake that
                offers a chance for Laxmi customers to win a variety of prizes,
                including 10g Gold coins being a grand attraction, the Gift
                Vouchers, and one Mega Winner to take away a brand-new iPhone.{" "}
                <br />
                <br />
                It is time to double up the celebration this Diwali, with Laxmi
                Golden Jubilee Bonanza! What are you waiting for? Enter Now!
              </p>
              <Link to={diwaliform}>
                <button href type="button" className="gofor_btn">
                  #LaxmiGoForGold
                </button>
              </Link>
            </div>
          </div>
          {/* {width < 600 ? (
            <img className="shilpa_shetty_mobile" src={shilpaimage} />
          ) : null} */}
        </div>
      </section>
    </>
  );
};

export default DiwaliAbout;
