import React, { useState, useEffect } from "react";
import { Data } from "./Data";
import { IconContext } from "react-icons";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import "./Faqpage.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const Accordion = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    const yOffset = -170;

    const element = document.getElementById(clicked);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [clicked]);
  return (
    <>
      <IconContext.Provider value={{ color: "#1c1c1c", size: "25px" }}>
        <div
          className="accordionsec"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="1000"
        >
          <div className="acc_container">
            {Data.map((item, index) => {
              return (
                <>
                  <div className="accoedion_box" id={index}>
                    <div
                      className="acc_wrap"
                      onClick={() => toggle(index)}
                      key={index}
                    >
                      <h2 className="title">{item.title}</h2>
                      <span className="acc_span">
                        {clicked === index ? <FiArrowUp /> : <FiArrowDown />}
                      </span>
                    </div>
                    <div className="acc_dropdown">
                      {clicked === index ? (
                        <div className="">
                          {item.faqs.map(({ question, answer }, i) => (
                            <>
                              <h3 className="acc_question">{question}</h3>
                              <p
                                className="acc_p"
                                dangerouslySetInnerHTML={{ __html: answer }}
                              ></p>
                            </>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            })}
            <div className="line"></div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Accordion;
