import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link as ScrollLink } from "react-scroll";
import "./DiwaliPage.scss";
//swiper imports
import "swiper/swiper-bundle.css";
import fbicon from "../../../images/landing-page/fbicon.svg";
import instaicon from "../../../images/landing-page/instaicon.svg";
import youtubeicon from "../../../images/landing-page/youtubeicon.svg";
import linkedinicon from "../../../images/landing-page/linkedinicon.svg";
import laxmilogo from "../../../images/landing-page/laxmilogo.svg";
import whiteinstaicon from "../../../images/landing-page/whiteinstaicon.svg";
import whitefbicon from "../../../images/landing-page/whitefbicon.svg";
import whiteyoutubeicon from "../../../images/landing-page/whiteyoutubeicon.svg";
import whitelinkedinicon from "../../../images/landing-page/whitelinkedinicon.svg";
import hamburger from "../../../assets/hamburger.svg";
import cross from "../../../assets/cross.svg";
//hooks
import useWindowDimensions from "../../../useWindowDimensions";
import MetaDecorator from "../../../componentsnew/MetaDecorator/MetaDecorator";
import { NavLink } from "react-router-dom";
import { buttonbackground } from "../../../images";
import ReactCanvasConfetti from "react-canvas-confetti";

import {
  diwali,
  diwaliabout,
  diwaliform,
  diwaligallery,
  diwalitermscondition,
  winners,
  diwaliwinner,
  termsandconditions,
} from "../../../helpers/constant-words";
const body = document.querySelector("body");

const canvasStyles = {
  position: "absolute",
  width: "8em",
  height: "5em",
  top: "-3em",
  left: "-2em",
  zIndex: "-1",
  pointerEvents: "none",
};

const Diwaliheader = (props) => {
  const { width } = useWindowDimensions();
  const refAnimationInstance = useRef(null);
  const [copyHashTag, setCopyHashTag] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 1 },
        particleCount: Math.floor(500 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.5, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.5, {
      spread: 100,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 20,
      startVelocity: 25,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 20,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    document.querySelector(".px-0.MainNavBar.shadow-lg").style.display = "none";
    document.querySelector(".CyanBg.MainFooter").style.display = "none";
    if (body) {
      body.style.height = "auto";
    }
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (copyHashTag) {
      let copiedTimeout = setTimeout(() => {
        setCopyHashTag(null);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copyHashTag]);

  useEffect(() => {
    if (isMenuOpen) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <>
      <main className="diwali_page_main">
        <header className="diwali_header">
          <img
            src={isMenuOpen ? cross : hamburger}
            alt="Hamburger Icon"
            className="hamburger_icon"
            onClick={() => handleMenuClick()}
          />
          <figure className="laxmilogo_wrapper">
            <a href="/">
              <img src={laxmilogo} alt="laxmi logo" className="laxmilogo" />
            </a>
          </figure>
          <div className={`nav_flex ${isMenuOpen ? "active" : ""}`}>
            <ul className="nav_links">
              <li className="confetti_animation">
                  <ReactCanvasConfetti
                  refConfetti={getInstance}
                  style={canvasStyles}
                  className="confetti_canvas"
                /> 
                <NavLink className="white_cta" to={winners}>
                  Winners
                </NavLink>
                <img
                  className="button_background"
                  src={buttonbackground}
                  alt="button background"
                />
              </li>
              <li>
                <NavLink to={diwaliabout}>About</NavLink>
              </li>
              {/* <li style={{ fontWeight: "bold" }}>
                <NavLink to={diwaliform}>#GoForGold</NavLink>
              </li> */}
              <li>
                <NavLink to={diwaligallery}>Gallery</NavLink>
              </li>
              <li>
                <NavLink to={diwalitermscondition} target="_blank">
                  T&C
                </NavLink>
              </li>
            </ul>
            <div className="social_icons">
              <a
                href="https://www.facebook.com/LaxmiFoodsBrand/"
                target="_blank"
              >
                <img
                  src={width >= 768 ? fbicon : whitefbicon}
                  alt="social media icon"
                />
              </a>
              <a
                href="https://www.instagram.com/laxmifoodsbrand/?hl=en"
                target="_blank"
              >
                <img
                  src={width >= 768 ? instaicon : whiteinstaicon}
                  alt="social media icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCA-ojVe3STlOlTOzGzZhYwQ/featured"
                target="_blank"
              >
                <img
                  src={width >= 768 ? youtubeicon : whiteyoutubeicon}
                  alt="social media icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/houseofspices"
                target="_blank"
              >
                <img
                  src={width >= 768 ? linkedinicon : whitelinkedinicon}
                  alt="social media icon"
                />
              </a>
            </div>
          </div>
        </header>
      </main>
    </>
  );
};

export default Diwaliheader;
