import React, { useEffect } from "react";
import "./style.scss";
import "../LandingPage/LandingPage.scss";
import laxmilogo from "../../images/landing-page/laxmilogo.svg";
import LandingPageFooter from "../LandingPageFooter/LandingPageFooter";
import AOS from "aos";
import "aos/dist/aos.css";

const TermsAndConditions = () => {
  useEffect(() => {
    document.querySelector(".px-0.MainNavBar.shadow-lg").style.display = "none";
    document.querySelector(".CyanBg.MainFooter").style.display = "none";
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section className="termssec" data-aos="fade" data-aos-duration="1000">
        <div className="my_container">
          <a href="/50-years-contest" className="laxmilogo_wrapper">
            <img src={laxmilogo} alt="" className="laxmilogo" />
          </a>
          <h1 className="terms_h1">Terms & Conditions </h1>
          <p className="terms_desc no_purchase">
            NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT
            IMPROVE YOUR CHANCES OF WINNING.
          </p>
          <p className="terms_desc">
            The ‘50 Years Giveaway’ is governed exclusively by the laws of the
            United States. You are not authorized to participate in this contest
            if you are not located within the fifty (50) United States, the
            District of Columbia, or Puerto Rico.
          </p>
          <div className="block_wrapper">
            <h4 className="terms_para_heading">1. Dates Of Contest</h4>
            <p className="terms_desc">
              Laxmi’s 50 Years Giveaway begins on 28th June, 2022 - 9:00 AM and
              ends on 15th August, 2022 - 12:00 AM.
            </p>
          </div>
          <div className="block_wrapper">
            <h4 className="terms_para_heading">2. Eligibility</h4>
            <p className="terms_desc">
              ‘Laxmi’s 50 Years Giveaway’ is open only to legal residents of the
              50 United States, the District of Columbia, or Puerto Rico, who
              are 18 years of age or older or the age of majority in their state
              of residence (which is 19 in Alabama, Nebraska, 21 in Mississippi,
              and 18 in all other states). Void outside of the 50 United States,
              the District of Columbia, Puerto Rico, and where prohibited,
              taxed, or restricted by law. Officers, directors, managers, and
              employees of House Of Spices, and each of its respective parents,
              subsidiaries, affiliates, and related companies and their
              respective advertising and promotion agencies, consultants, and
              agents, as well as immediate family members and/or those living in
              the same household of each such person, and any others engaged in
              the development, production, execution or distribution of this
              Giveaway are not eligible to participate. “Immediate family
              member” shall be deemed to mean husband, wife, children, mother,
              father, sister, sister-in-law, brother, or brother-in-law. Laxmi’s
              50 Years Giveaway may only be entered in or from the 50 United
              States, the District of Columbia, or Puerto Rico, and entries
              originating from any other jurisdiction are not eligible for
              entry. All federal, state and local laws and regulations apply.
            </p>
          </div>

          <div className="block_wrapper">
            <h4 className="terms_para_heading">3. How To Enter</h4>
            <div className="enter_step_wrapper terms_desc">
              <ul>
                <li>
                  <span>Step 1 - </span>Fill out a form on the landing page.
                  Here’s the{" "}
                  <a href="/50-years-contest" className="herelink">
                    link
                  </a>{" "}
                  .{" "}
                </li>
                <li>
                  <span>Step 2 - </span>Make a video and go down the memory
                  lane, celebrate your journey in the US by reaching out to
                  friends, family, and loved ones who have contributed
                  unforgettable memories or tell us your story about how you
                  came across Laxmi products during your American journey or the
                  first memorable experience you had with food and your loved
                  ones in the US.
                </li>
                <li>
                  <span>Step 3 - </span>Upload it on your social media as well
                  as add on the website using the following hashtags: <br />
                  a) #LaxmiYadoonKiRecipe b) #50YearsOfLaxmi c)
                  #ReachOutWithLaxmi
                </li>
              </ul>
            </div>
          </div>

          <div className="block_wrapper">
            <h4 className="terms_para_heading">
              4. Process Of Selecting Winners{" "}
            </h4>
            <p className="terms_desc">
              The 50 years giveaway is subjective in nature and there is no
              right or wrong in the desired entries for the giveaway. However,
              the potential prize winners will be selected based on their
              stories. Brand’s decision will be affirmative and binding under
              all circumstances. <br />
              By entering Laxmi’s 50 Years Giveaway, entrants fully and
              unconditionally agree to be bound by these Official Rules and the
              decisions of the Contests Entities, which will be final and
              binding in all matters related to the contest. <br />
              To be eligible to receive a prize, all entrants must have an
              address on file or have provided an address when prompted at the
              time of entry. If an entrant has an incomplete address, the
              entrant will be disqualified and an alternate prize winner will be
              selected.
            </p>
          </div>
          <div className="block_wrapper">
            <h4 className="terms_para_heading">5. Prizes</h4>
            <p className="terms_desc">
              Winners will be declared in two categories.
            </p>
            <div className="table_wrapper">
              <table className="terms_table terms_desc">
                <tbody>
                  <tr>
                    <th>Type</th>
                    <th>Number of winners</th>
                    <th>Giveaway</th>
                  </tr>
                  <tr>
                    <td rowSpan="2">Mega Winners</td>
                    <td>1</td>
                    <td>IPAD</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>IPHONE 1</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">Weekly Winners</td>
                    <td>2/Week</td>
                    <td>Amazon Vouchers</td>
                  </tr>
                  <tr>
                    <td>3/Week</td>
                    <td>Laxmi Gift Boxes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="sub_heading terms_desc">
              <div className="">5 Weekly Winners Will Be Declared</div>
              <ul>
                <li> 12th July - 1st set of weekly winner announced</li>
                <li> 19th July - 2nd set of weekly winners announced</li>
                <li> 26th July - 3rd set of weekly winners announced</li>
                <li> 2nd August - 4th set of weekly winners announced</li>
                <li> 9th August - 5th set of weekly winners announced</li>
                <li> 16th August - 6th set of weekly winners announced</li>
              </ul>
            </div>
            <div className="sub_heading terms_desc">
              <div>
                2 Mega Winners Will Be Declared During The Last Two Weeks Of
                August
              </div>
              <ul>
                <li> 22nd August - first mega winner - iPhone giveaway </li>
                <li> 29th August - second mega winner - iPad giveaway</li>
              </ul>
            </div>
            <p className="terms_desc">
              <span style={{ fontWeight: "bold" }}>Note:</span> The participant
              is eligible to participate in the contest if they didn’t win the
              previous Weekly Winners contest. In case, the participant won the
              Weekly Winners contest he/she is still eligible to participate in
              the Mega Winners contest.
            </p>
          </div>

          <div className="block_wrapper">
            <h4 className="terms_para_heading">6. Prize Restrictions</h4>
            <p className="terms_desc">
              All prizes are non-transferable or assigned and no substitutions
              or cash redemptions will be done. Laxmi will not replace any lost
              or stolen prizes. In the case of unavailability of any prize,
              Laxmi reserves the right to substitute the prize of equal or
              greater value.
              <br />
              Each prize winner is solely responsible for all applicable
              federal, state, and local taxes, including taxes imposed on
              his/her income. <br />
              No more than the stated number of prizes outlined in these
              Official Rules will be awarded. Potential prize winners consent to
              the use of his/her name, likeness, biographical information, and
              voice in advertising worldwide without additional compensation (TN
              residents will not be required to sign a Publicity Release as a
              condition of winning a prize).
            </p>
          </div>

          <div className="block_wrapper">
            <h4 className="terms_para_heading">7. Notifications/Claims</h4>
            <div className="sub_head terms_desc">● Gift Vouchers</div>
            <p className="terms_desc">
              Potential Voucher winners will be notified by email. Voucher
              winners must respond to the notification within 5 business days.
              In the event that the Potential Prize Winner fails to respond to
              the notification within 5 business days then that potential Prize
              Winner is not eligible following these Official Rules and an
              alternate Prize Winner will be selected at random from among the
              remaining non-winning eligible entries received during the
              giveaway.
            </p>
            <div className="sub_head terms_desc">● Weekly & Mega Prizes</div>
            <p className="terms_desc">
              Potential Weekly & Mega Prize winner's prize will be shipped
              within 10 working days from the drawing date via the United States
              Postal Service to the address supplied at the time of entry. In
              the event the prize is returned, the address is insufficient or
              incomplete for shipping purposes, or undeliverable for any reason,
              the prize winner will be disqualified and an alternate prize
              winner will be selected from the remaining eligible entries
              received. If the alternate prize winner’s prize is returned, the
              address is insufficient or incomplete for shipping purposes, or
              undeliverable for any reason, the alternate prize winner will be
              disqualified and the prize will not be awarded. No compensation
              will be provided for any prizes that are not able to be awarded to
              a prize winner. <br /> <br /> The value associated with the prize
              is taxable as income and the Weekly or Mega prize winner is solely
              responsible for any taxes, including, but not limited to all
              applicable federal, state and local taxes that become due
              concerning the value of the prize. <br /> <br /> The Potential
              Weekly or Mega prize winner will be required to sign and return
              (except where prohibited) an Affidavit of Eligibility and
              Liability/Publicity Release and a W-9 tax form (the “Paperwork”)
              within 10 days of the Notification. If any prize winner is
              considered a minor in his/her jurisdiction of residence (but must
              be over the age of 18), the Paperwork must be completed and signed
              by his/her parent or legal guardian, and such prize will be
              delivered to minor’s parent/legal guardian and awarded in the name
              of parent/legal guardian. After verification of the Affidavit, the
              prize will be mailed using the United States Postal Service
              Priority Mail. The potential prize winner must return the
              Paperwork within the date and time indicated in the Notification.
              If a potential prize winner fails to return the Paperwork within
              the date and time indicated in the notification or is found to be
              ineligible, or if he/she does not comply with the Official Rules,
              such potential prize winner’s entry will be disqualified and
              another potential prize winner will be selected from the remaining
              eligible entries received. If the notification is returned as
              undeliverable, this will result in disqualification and an
              alternate prize winner will be selected from all remaining
              eligible entries.
            </p>
          </div>

          <div className="block_wrapper">
            <h4 className="terms_para_heading">8. Conditions</h4>
            <p className="terms_desc">
              Laxmi’s 50 Years giveaway is conducted in English but the video
              can be in Hindi. All federal, state and local taxes are the sole
              responsibility of the prize winners. By participating in this
              contest, entrants and prize winners agree to be bound by these
              Official Rules and the decisions of the entities, which are final
              in all respects and not subject to appeal. Laxmi reserves the
              right to disqualify permanently from this Giveaway any entrant if
              it believes has violated these Official Rules and verify the
              eligibility of entrants. Neither Laxmi nor anyone acting on its
              behalf will enter into any communications with any entrant
              regarding this Giveaway, except as expressly outlined in these
              Official Rules. By participating, the entrants, and prize winners
              release Laxmi and each and all of its entities from any liability,
              damages, or causes of action (however named or described)
              concerning or arising out of participation in the Contest, and/or
              the receipt or use/misuse of any prize awarded, including, without
              limitation, liability for personal injury, death or property
              damage. <br />
              <br />
              Failure to comply with these Official Rules may result in
              disqualification. All the materials are subject to verification
              and are void if:
              <br />
              (a) Not obtained following these Official Rules and through
              legitimate channels
              <br /> (b) Any part is counterfeited, altered, defective, damaged,
              illegible, reproduced, tampered with, mutilated, or irregular in
              any way
              <br />
              (c) Are obtained where prohibited, or
              <br /> (d) They contain printing, typographical, mechanical, or
              other errors. <br /> <br />
              In the event any portion of this giveaway is compromised by
              activities beyond the control of Laxmi which could corrupt or
              impair the administration, security, fairness, or proper play of
              the giveaway, Laxmi reserves the right at its sole discretion to
              modify, suspend or terminate the giveaway. <br /> Winning a prize
              constitutes permission for Laxmi and its agencies to use prize
              winners' names, addresses (city & state), voices, and/or
              likenesses for purposes of advertising, promotion, and publicity,
              for this only, without further compensation, unless prohibited by
              law. Laxmi may prohibit an entrant from participating in the
              Laxmi’s 50-Year giveaway or winning a prize if, in its sole
              discretion, it determines that said entrant is attempting to
              undermine the legitimate operation of the giveaway by cheating,
              deception, or other unfair playing practices (including the use of
              automated quick entry programs) or intending to annoy, abuse,
              threaten or harass any other entrants or Laxmi Entities.
              <br /> If for any reason this giveaway is not able to be conducted
              as planned, including, but not limited to, because of infection by
              computer virus, bugs, tampering, unauthorized intervention, fraud,
              or any other causes beyond the reasonable control of Laxmi which
              corrupt or affect the administration, security, fairness,
              integrity or proper conduct of the giveaway, then Laxmi reserves
              the right at its sole discretion to cancel, terminate, modify or
              suspend the 50 Years giveaway and randomly draw from those entries
              received up to the cancellation/suspension date to award prizes.
              <br />
              <br />
              <span className="span_tag"> CAUTION</span>: Any attempt by a
              person to deliberately damage or undermine the legitimate
              operation of the giveaway may be in violation of criminal and
              civil laws and should such an attempt be made, Laxmi reserves the
              right to seek remedies and damages (including attorney’s fees)
              from any such person to the fullest extent of the law, including
              criminal prosecution. Laxmi’s failure to enforce any term of these
              official rules shall not constitute a waiver of these provisions.
            </p>
          </div>

          <div className="block_wrapper">
            <h4 className="terms_para_heading">9. No Liability </h4>
            <p className="terms_desc">
              By participating, entrants and prize winners agree to release,
              discharge, indemnify and hold harmless the Laxmi entities and each
              of their respective officers, directors, employees,
              representatives, and agents from and against any claims made by
              any entrant, prize winner, or any other third party, related in
              any way to the operation of this contest as well as any other
              claims, damages or liability due to any injuries, damages or
              losses to any person (including death) or property of any kind
              resulting in whole or in part, directly or indirectly, from
              receipt, acceptance, possession, misuse or use of a prize or
              participation in any promotion-related activity or participation
              in this 50th Year Giveaway.
            </p>
          </div>
          <div className="block_wrapper">
            <h4 className="terms_para_heading">10. Use Of Data </h4>
            <p className="terms_desc">
              Laxmi’s 50 years Giveaway will be collecting personal data about
              entrants online, following its privacy policy. By participating in
              this Contest, entrants hereby agree to the collection and usage of
              their personal information and acknowledge that they have read and
              accepted the privacy policy.
            </p>
          </div>
          <div className="block_wrapper">
            <h4 className="terms_para_heading">11. Winners List </h4>
            <p className="terms_desc">
              To obtain a list of Prize Winners, visit Laxmi’s social media
              handles or the Laxmi website.
            </p>
          </div>
        </div>
      </section>
      <LandingPageFooter />
    </>
  );
};

export default TermsAndConditions;
