import {
  unmuktChand,
  ashaShiv,
  omiVaidya,
  pujaReza,
  aarthiSampath,
  kunalLamba,
  ayesha,
  mariumArif,
  anjana,
  testiimg4,
  testiimg3,
  testiimg2,
  rabiaAmmar,
  AkbarPirani,
  BhavanaPatel,
  DianaHayden,
  FamilyKarma,
  FatimaAli,
  MsPooja,
  NikhilMerchant,
  Priya,
  Samira,
  ShubhraRamineni,
  diwaligallery1,
  diwaligallery2,
  diwaligallery3,
  kunallamba,
  kirannivi,
  archanamundhe,
} from "../../images";

const embed = "https://www.youtube.com/embed/";
export const diwaligalleryData = [
  {
    ytLink: embed + "YjH0GWeM5bg",
    img: kunallamba,
    Name: "Kunal Lamba",
  },
  {
    ytLink: embed + "vyalVxJdlo4",
    img: kirannivi,
    Name: "Kiran & Nivi",
  },
  {
    ytLink: embed + "5Ri_X5_wb2E",
    img: archanamundhe,
    Name: "Archana Mundhe",
  },
];

export const weeklyWinners = [
  {
    winnerName: "Gaurav Raj Lamichchane",
  },
  {
    winnerName: "Usha Ghimire",
  },
  {
    winnerName: "Nupur Chakrovarthy",
  },
  {
    winnerName: "Rahul Anandan ",
  },
  {
    winnerName: "Rosa Mathew",
  },
  {
    winnerName: "Priya Kulkarni",
  },
  {
    winnerName: "Niharika P",
  },
  {
    winnerName: "Kavya Chandre Gowda",
  },
  {
    winnerName: "Dr. Varsha Singh",
  },
  {
    winnerName: "Vishlesha Rana",
  },
  {
    winnerName: "Alina Alam",
  },
  {
    winnerName: "Gayathri Murthy",
  },
  {
    winnerName: "Divya Prabhakar",
  },
  {
    winnerName: "Mangala Gowri Subramanian",
  },
  {
    winnerName: "Dhruv Patel",
  },
  {
    winnerName: "Karishma M Gaitonde",
  },
  {
    winnerName: "Sujata Shukla",
  },
  {
    winnerName: "Ankush Jain",
  },
  {
    winnerName: "Sarah Siddiqui",
  },
  {
    winnerName: "Kanupriya Gupta Mujawdiya",
  },
  {
    winnerName: "Abhishek Parekh",
  },
  {
    winnerName: "Ganell Morris",
  },
  {
    winnerName: "Nupura Kulkarni",
  },
  {
    winnerName: "Viji",
  },
  {
    winnerName: "Narmada Sirasanambedu",
  },
  {
    winnerName: "Yama Bhanu Prakash Reddy",
  },
  {
    winnerName: "Saman Khan",
  },
  {
    winnerName: "Aishwarya Ramakrishnan",
  },
  {
    winnerName: "Sakshi Bhalla",
  },
  {
    winnerName: "Shrushti Parikh",
  },
];

export const winnerData = [
  {
    golden: "Ramu Rajula",
    tabName: "Week 12",
    winners: [
      "Yasaswi Kolasani",
      "Parvesh Parvesh",
      "Satheiish Vaidyanathan",
      "Tirumala Nidamanuri",
      "Swetha Gadamsetty",
      "Ekta Paliwal",
      "VIJAYKUMAR NAYEE",
      "Akash Karda",
      "Pooja Gouroji",
      "Suhasi Goly",
      "Hasuben Kapletiya",
      "Sunil Aryal",
      "Ashok Rayavaram",
      "Adarsh Kumar",
      "Sandeep Kapur",
      "Swarna ban",
      "Aum Patel",
      "Sangita Patel",
      "Sukesh Parikh",
      "Dhanalakshmi Tammineni",
      "Deepak Tiwari",
      "Mahalakshmi Gurram",
      "Sai kiran Munugoti",
      "abhimanyu bakshi",
      "Niveditha Namilikonda",
      "Rajiv Patel",
      "Lavanya Madduri",
      "FNU Ananda",
      "Rajinder Maan",
      "Darshan akula",
      "Priteshkumar Shah",
      "Pooja Engle",
      "Shilpa Yeradla",
      "Aum Patel",
      "Praveen Dodda",
      "venkatesh mahendrakar",
      "Payal Jain",
      "Summaiya Arshad",
      "Alpa Patel",
      "Mrudula Wadake",
      "Venkata Vemparala",
      "Asma Dabheliya",
      "Abdul Samiullah",
      "Pradeep Ch",
      "Jeevan Yelety",
      "SANDEEP CHINTAPATLA",
      "Mahesh Wadhe",
      "Ramesh Vepakomma",
      "priyanksinh kapletiya",
      "Vinaben Joshi",
      "Karthik Pamulaparthy",
      "Aakanksha Vyawahare",
      "Vijayalakshmi angaiyan",
      "Gurmit Ram",
      "Pratik Lole",
      "Supreet Tomar",
      "Sanjay Verma",
      "Rabia Gulkaya",
      "Shalinder Tiku",
      "Pranay Vennapusa",
      "Bhargavi Rallabandi",
    ],
  },
  {
    golden: "Sultana <br />Lalani",
    tabName: "Week 11",
    winners: [
      "YOGI SUTHAR",
      "Vahini	Potluri",
      "Sushmitha Kodavati",
      "Divya Kalaria",
      "Kawaljeet Singh",
      "Kishore Ganipineni",
      "Shikha Parekh",
      "Prithvi Kocherla",
      "Shankar  Acharya ",
      "NAGA SASANAPURI",
      "Aisha Kalhoro",
      "Shankar Acharya ",
      "KANTI RANA",
      "Naga Manda",
      "aum patel",
      "Hemant Patil",
      "Nishkarsh Jain",
      "Vivek Kumar",
      "Asmeeta Manaseeya ",
      "Nitin Mohan",
      "venkata Gangavarapu ",
      "Kapil Chintala",
      "Hema Patel",
      "Nagababu Mareedu",
      "Amita Suthar",
      "Aameerah Ginwala",
      "Ashish Umrani",
      "Sureshbhai Patel",
      "Jaikumar Sharma",
      "Chandini Boyina",
      "Rakesh Patel",
      "Madiha Butt",
      "Kuldeep Kaur",
      "Gowtham Mallepaka",
      "Shekhar Singh",
      "Gangadhar Bonda",
      "veerareddy Kolan",
      "Swapna Vanga",
      "Casey Boyd",
      "ILIYAS HUSSAIN",
      "Pavan Kothapalli",
      "Indranil Dasgupta",
      "Ramalakshmi Neti",
      "Raja Mani",
      "Money Madineni",
      "Abhay Dare",
      "Kushavantha Kyanahalli ",
      "Nambirajan Jayaraman",
      "Pratikkumar Patel",
      "Chirun Ananthaneni",
      "Vineeta Bhagchandani",
      "Deval Thakkar",
      "Parame Boddu",
      "Sowndrapriya Muruganantham ",
      "priyanksinh kapletiya",
      "Manohar Reddy",
      "vasu chemuru",
      "Satyanarayana Satti",
      "Kalyani Mothukuri",
      "Deepali Patil",
      "Jinal Joshi",
    ],
  },
  {
    golden: "Sasidhar <br />Damireddy",
    tabName: "Week 10",
    winners: [
      "Harneet Khanna",
      "Senthil Rajendarn",
      "Sai Kotcharlakota ",
      "Nagaraj Thakur",
      "Mohan ganesan",
      "Chinmay Patel",
      "Nithin Mali",
      "Kuldeep Sharma",
      "Rohith Jetti",
      "Joga Singh",
      "DIVYA KOTA",
      "Ashwin Patel",
      "Bijjol R",
      "Krishna Vemuri",
      "Muktesh <br />Mistry",
      "Chander Sethi",
      "Lohit Bannaravuri",
      "Swathi Loka",
      "Sushma Singh",
      "Pratik Lole",
      "Amardeep Singh",
      "suresh muppalla",
      "Sridivya yella",
      "I Singh ",
      "Bibi Khan",
      "Dev jaisi",
      "Anand Kumar",
      "Dunellaben Patel",
      "Rajesh Patel",
      "Mukul Sathe",
      "SANDEEP KALARI",
      "Mehreen K",
      "Aakanksha Patel",
      "Swathi Ananthala",
      "Ramalingam Sekar",
      "Manoj Patel",
      "Naga Kollepara",
      "Hema Yalamanchi",
      "Arun Illendula",
      "Nurjaha <br />Dinani",
      "Dravidaselvi Loganathan ",
      "phanindra guthi",
      "Alka Satpalkar",
      "Shaila Patel",
      "Sadhanaben Patel",
      "Jeya Akula",
      "GIRISH DALVI",
      "Ramnath Desa",
      "MADHUSUDHANA CHIPPAGIRI",
      "Harbhajan Singh",
      "Anand Kumar",
      "Jayakumar Nallapalli",
      "Sunil Ali",
      "Huma Mohammed",
      "Virnica Gautam",
      "Nishantkumar Patel",
      "ANUVRAT JAINDUNGARWAL",
      "Sudharani Rajamanyam",
      "Ajay Potharaju",
      "veera manda",
      "Maulik Patel",
    ],
  },
  {
    golden: "Ramya <br />Maguluri",
    tabName: "Week 9",
    winners: [
      "Lesli Faras",
      "Nilesh Thacker",
      "Ameer Shohatee",
      "srinivas n",
      "Bhumika Patel",
      "Sunil Mutyala",
      "VINEET Bhasin",
      "Rabia Farooq",
      "Divya Bodapati ",
      "Siddhesh Parab",
      "Sunil Sahu",
      "Swathi Beeravelli",
      "Prasanth Madduri",
      "Amol Gupta",
      "Anil Rajput",
      "bhavana kollimarla",
      "Veera Kongalla",
      "Karun Vinay",
      "Fnu Devanshu",
      "Ashwini Mundhe",
      "Jagdeep singh",
      "Venkata Miriyala",
      "Hansa Patel",
      "Neha Chauhan",
      "Niti Sharma",
      "Sahithi Bhatt",
      "Vishwanath Narake",
      "Vanajadevi Cherevuru",
      "Shazia SABIR",
      "Archana Bandaru",
      "Charanjit Singh",
      "Ashish Galav",
      "PoojaNag Nagaraju",
      "naveen anamaneni",
      "Divya bollineni",
      "sai billa",
      "KULWANT SANGHA",
      "Jaspal Singh",
      "Neha Narula",
      "Vishalkuamr Mandaliy",
      "Govind Jaipuria",
      "Srikanth Muppala",
      "Sunita Bhandare",
      "Abdullatheef Abdulrahman",
      "Nadiya Parekh",
      "Krishna Amaravadi",
      "Sree Kovuru",
      "Richa sharma ",
      "Muskan Aga",
      "Gaurav Gupta",
      "Lakshmi Ravi",
      "Brenda Bussolaro ",
      "Chetana Patel ",
      "Divya Bajwa",
      "Sandhya Vempati",
      "Anand Kumar ",
      "Bilal Anarwala",
      "Sanjay Gurram ",
      "Raunak Kumar",
      "Mahith Adapala",
      "Sukanya Shankara",
    ],
  },
  {
    golden: "Viralkumar <br />Patel",
    tabName: "Week 8",
    winners: [
      "shailaja pottu",
      "Anand Kumar ",
      "Kavitha Rao",
      "SUBODH DESHPANDE",
      "Maram Mohd",
      "Krushi Shah",
      "Karthik Aravapalli",
      "Aarti Gupta",
      "Krithin  Palla",
      "sandeep gunnam",
      "Swathi Nampally",
      "Sudipta Mukherjee",
      "Rahul Vashisht",
      "James Mughal",
      "Zaharaben  Maknojia ",
      "Nilaykumar Patel",
      "Bhavik Thakkar",
      "HARIPRASAD MUSTYALA",
      "Chandra Palle",
      "Jashandeep Singh",
      "ISHAN JINDAL",
      "BHAVESHKUMAR PATEL",
      "Vikas Desraj",
      "Pranav Shah",
      "Rajnish Rajnish",
      "Shahena Akter",
      "Khyati Shah",
      "Amit Bansal",
      "Lavanya Trivedi",
      "Praveen Nallana",
      "Riya Babvani",
      "Sahejdeep Singh",
      "santosh khule",
      "Saajan Cheema",
      "Barkat Lalani",
      "Naga Mamillapalli",
      "Kiritkumar Patel",
      "Hasuben Kapletiya",
      "Bikash Behera",
      "Pranati Nath",
      "Sima Lalani ",
      "Soniya Goyal",
      "jyothi Vasamshetty",
      "Shila Desai",
      "Abhiram Boyapati",
      "Mounika N",
      "Ajay Sharma",
      "Gajendrasinh Kapletiya",
      "Aman Singh",
      "Davinder Gill",
      "Manisha Gaddam",
      "Milind Naik",
      "Praveen Katukuri",
      "Ranjith Chinni",
      "Sushma Dindu",
      "Priti Patel",
      "Sunny Gandhi",
      "Vinay Rao Bas",
      "Sanjiv Shah",
      "Shashi Sharma",
      "Urmila Gupta",
    ],
  },
  {
    golden: "Subhani <br />Shaik",
    tabName: "Week 7",
    winners: [
      "Mariah Dhalai",
      "Husna Kh",
      "Yaswanth Kodali",
      "Mandeep Singh",
      "Manjunatha Jayakumar",
      "Vijay Kose",
      "Sruti Nanda",
      "Rohit Saxena",
      "Yogita Kumari",
      "Fnu Hamidullah",
      "Heena Patel",
      "Prabha Wati",
      "Ali Mohamed",
      "Chandrakanth Barad",
      "Ram Yar",
      "Nishant Jain",
      "Vinay Thatiparthy",
      "Chandani Patel",
      "Sahitya Mehra",
      "Bhavana Sarawagi",
      "Vijay Koti",
      "Raaghu  Thippeswamy",
      "Nagaraju Chappa",
      "Chandan Kumar",
      "Raghu Yalamanchili",
      "Tushar Joshi",
      "Kainat Ghazal",
      "Indumathi Aravapalli",
      "Akhila Kathari",
      "Madhu Karthikeyan",
      "Venkata Javvaji",
      "Kalyan Nimma",
      "Kiran Bhaskar",
      "Karthikeyan Vaiyapuri",
      "Saran Thanikonda",
      "Shivateja Tadaka",
      "Snehal Naidu",
      "Deepak Deshpande",
      "Swathi Maddali",
      "Shilap Chen",
      "Naga Kommidi",
      "Swapna Ketavarapu",
      "Rajan Dobariya",
      "Zahara Hooda",
      "Anusha Hindka",
      "Bhavana Mendu",
      "Shailaja Newaskar",
      "Sagar Narala",
      "Mayank Mehrishi",
      "Sunny Ahir",
      "Anand Hirawat",
      "Yamini Chigurupati",
      "Harjit Kaur",
      "Sheza Taranum",
      "Rajat Kamble",
      "Sunil Kumar",
      "Vasavi Vysyaraju",
      "Lipika Sahoo",
      "Damodar Nayana",
      "Jagpreet Dhaliwal",
      "Dhanesh Khatri",
    ],
  },
  {
    golden: "Seema <br />Kumari",
    tabName: "Week 6",
    winners: [
      "Pavan Kancheti",
      "Deep Reddy",
      "Uma Ulisi",
      "TamilIlakkiya Arulmurugan",
      "Saumya Agrawal",
      "Sreenivas Gudipati",
      "Mounika Nallamothu",
      "Venkateshwary Pasumarthy",
      "Ken David",
      "Nileshvari Patel",
      "Amin Noorani",
      "Ravi Sharma",
      "Md Tamjid adnan",
      "Yeswanth Lekkala",
      "Venki Manoharan",
      "Hiral Patel",
      "Vandana Rajpurohit",
      "Chanchal Gill",
      "Rupal Trivedi",
      "Muhammad Ramzan",
      "Krishna Bojanki",
      "Muhammad SHAHID",
      "Vinaykumar Dori",
      "Sreedhar Bunga",
      "FNU manoj",
      "Sai Anirudh Garnapally",
      "Kumar Robin",
      "Silpa Polavarapu",
      "Sahista Mansuri",
      "Vijayakumar Rajasekaran",
      "Bhargavi Lavanya",
      "KAPIL NAGDIA",
      "Dalveer Kaur",
      "Nitu Ravi Verma",
      "Samina Chaudhary",
      "Abid Ramani",
      "Preeti Sidhu",
      "Sree Rotte",
      "padmaja mallu",
      "Rakesh Mehta",
      "Ram G",
      "Soni Kumari",
      "Saroj Rani",
      "Mustan Singh",
      "manu patel",
      "Bakkiyalakshmi Dhanasekar",
      "Ansul Mudbhari",
      "Rupal Patel",
      "Venkateswarlu Namburu",
      "Neeraj Bhatia",
      "CHANDRASEKAR SEMBAN",
      "Sunil Kumar",
      "Zainab Muthanna",
      "Seethend Dummansoor",
      "Madhu Kapoor",
      "Mahesh Kandregula",
      "M. Wajid Aleem",
      "Meenakshi Honkanadavar",
      "Kajal Kukadiya",
      "Shameem Ameerudeen",
      "Kartik Shah",
    ],
  },
  {
    golden: "Khuram <br />Shahzad",
    tabName: "Week 5",
    winners: [
      "Ravi Ambikapathy",
      "Neha Tare",
      "Sumandeep Ghotra ",
      "Hari Kalkoda",
      "Prem Pandey ",
      "Naman Patel",
      "Srinivasa Tatikonda ",
      "Ambika Rani",
      "Muhammad Kalim",
      "chinna tanikonda",
      "Dharti Patel",
      "Aruna Baraiya ",
      "Ambreen Danish",
      "Snehal Sathawane",
      "Kajal Shah",
      "Praveen Kasireddy",
      "Tejas Raviraj",
      "saritha vasireddy",
      "Yateesh Shukla",
      "Ramanujan Archakam",
      "Sai Pasupuleti ",
      "Rajesh Vandanapu",
      "Rozina Moiz",
      "Vanita Gandhi",
      "Jagdeep Singh",
      "Bindu Devalla",
      "Sukhjeet Singh",
      "Vidhyarubini Nallusamy ",
      "Manisha Hanspal",
      "Neha Rani",
      "Khyati Vaghela ",
      "Radhika Siriki",
      "Devanshi Patel",
      "Naveenkumar Vuppu",
      "Richa Varshney ",
      "Pranjal Mahant",
      "Yash Trivedi",
      "Ravi Thummalapally ",
      "Barkha Soni",
      "Madhulika Verma",
      "Sreeja Boppana",
      "Ankitkumar Patel",
      "Hina Deliwala",
      "Neeraja Yarrabothula",
      "Vibhali Jagtap",
      "Karuna M",
      "Ravinder Ravinder",
      "Himaja Sunkara",
      "Tithi Thakker",
      "Shivam Patel",
      "Sushil Jain",
      "Satya Beri",
      "Parita Patel ",
      "Shree Raut",
      "NAVEEN VANAMALA",
      "Suresh Kaushik",
      "RAMA MANNEM",
      "HITESH DUDHAT",
      "Cecil christian",
      "Nikitha Nagulapati ",
      "Anjani Prabaharan",
    ],
  },
  {
    golden: "Pruthvi <br />Manjunatha",
    tabName: "Week 4",
    winners: [
      "Ahamadulla Shaik",
      "Vidwan Patlolla",
      "Eshaan Yadlapalli",
      "Krishna Pavuluri",
      "Gagan Vyas",
      "Lakshmi Narra",
      "Subhalakshmi Ramamoorthy",
      "Raghavendra Thavva",
      "Simaben Patel",
      "Hemanth Permareddy",
      "Satish Bogaiah",
      "Sulekha Gautam",
      "Krinesh Patel",
      "Falguni Patel",
      "Arvind Agrawal",
      "Ritu Parekh",
      "Gulshan Dhamani",
      "Priyal Patel",
      "Venugopal Moparthy",
      "Harika Jonnadula",
      "Jagdish Patel",
      "Jagdish Patel",
      "Anil Murari",
      "Ishtiyaq Ahmad",
      "Pradeep Kumar",
      "Sandhya Chandran",
      "Ranjini Kadarkarai",
      "Anand Sivakumar",
      "Mukthiswar Velagandula",
      "Padmaja Jami",
      "Raviraj Tippannavar",
      "Anand Kumar",
      "Bhavna Oswal",
      "Vivek Mara",
      "Anoop Aribandi",
      "Rameshbhai Patel",
      "Sagar Patel",
      "Vibha Shukla",
      "Chhaya Patel",
      "Madhu Bala",
      "Ruchi Patel",
      "Balaji Kandavel",
      "Neelakshi  Dhote",
      "Vijay Sankar",
      "Anudeep Simhadri",
      "Vidhi Mehta",
      "Sumaira Ayub",
      "Emeraldine Isaac",
      "Hemang Pokal",
      "Shweta Mudke",
      "Vikas Khandelwal",
      "Ashish Kishore",
      "Praneeth Madamsetty",
      "Varalakshmi Boddu",
      "Ashvin Patel",
      "Syed Zedi",
      "Shalini Komaragiri",
      "Varun Haria",
      "Sridhar Madala",
      "Sarika Dachepalli",
      "Sravanthi Vunnam",
    ],
  },
  {
    golden: "Nisarg <br />Patel",
    tabName: "Week 3",
    winners: [
      "jagrut thakkar",
      "ABDUL MUNSHI",
      "Rupa Desai",
      "N Hameed",
      "Sagarika Rajguru",
      "Mahesh Bhogadi",
      "Sheik Shafia",
      "Swapnil Phapale",
      "Nirali Patel",
      "Vijayakumar Radhakrishnan",
      "Kapil Gandhi",
      "Mohit Sehgal",
      "Avtar Singh",
      "Pankajkumar Patel",
      "Jagadeesh Koppisetti",
      "Hareshkumar Ramani",
      "Syed Hussain",
      "Jeewan Ram ",
      "Ramesh Tottadi",
      "Ar SH",
      "vaishali Chitalkar",
      "Anisha Mahajan",
      "Nitin Sharma",
      "Srinivas Ambati",
      "Prabhavati Barvalia",
      "Sunil Singh",
      "Chandra Pat",
      "Devendra Yadav",
      "Prasanth Pagadala",
      "Anand Kumar",
      "Phani Kalvakota",
      "vamsi ponnada",
      "Prince Sharma",
      "inderjeet kattaria",
      "Yorusalem Yohannes",
      "Jayesh Panchal",
      "Jagan Kanniappan",
      "Sushant Mahajan",
      "Nilesha Patel",
      "Shoukat Tufail",
      "Aruna Baraiya",
      "Kishore Kottalanka",
      "Poonam Parmar",
      "Abhaya Kulkarni",
      "Ojaswa Swarnkar",
      "Muhammad Shaheen",
      "Lalita Sharma",
      "Pawan Kumar",
      "Manjinder Kaur",
      "padma Bommareddy",
      "Rahul Jain",
      "Jayalaxmi Jungula",
      "Nisha Sharma",
      "Suday Mandal",
      "Nafisa Gajra",
      "Bhadreshkumar Bhuva",
      "Richa Jindal",
      "Vijay Mindhe",
      "Monica Khera",
      "Alex Patel",
      "Anup Rana",
    ],
  },
  {
    golden: "Kamal <br />Lalka",
    tabName: "Week 2",
    winners: [
      "Vruti Patel",
      "Cheng Ma",
      "Sagar Bhavsar",
      "Surya Palle",
      "Karan  Kapoor",
      "Sidra Batool",
      "Nishiba Riaz",
      "xapz xapz1",
      "Devikaben  Shah",
      "jigneshkumar mehta",
      "Siva Gottimukkala",
      "Prashanth Veeramalla",
      "Jyoti Sharma",
      "Balwinder  Singh",
      "Aparna Basani",
      "Anand Kumar ",
      "Siddhartha Konakondla",
      "ads laxmi",
      "Shaileshbhai Shah",
      "Ebenezer Kanamala",
      "Miguel Organillo",
      "Ankita Kolhe",
      "Munish  Kumar",
      "Nidhi Vyas",
      "Venmukil Mathivanan ",
      "Grishmaben Patel",
      "Raja Bikumandla",
      "Yasoda Namala",
      "Vruti Patel",
      "Amit Kumar",
      "Lohitha Kommuri",
      "Sayali Patil",
      "Naga Vulli",
      "Shaileshbhai Shah",
      "Shaileshbhai Shah",
      "Khyati Patel",
      "awais Mahmood",
      "Vikas Thange",
      "Joseph Reddy",
      "Sayroj Samnani",
      "Hiren Gajipara",
      "Neha Asthana",
      "Kuljit  Kaur ",
      "Stephen  Francis",
      "Radhika Nijampurkar",
      "Sabina Yeahia",
      "Fatima Azeem",
      "Abhilash Lallapati",
      "Jasvir Kaur",
      "MOHAMMAD HOQUE",
      "Vamshi Poladi ",
      "Kishore Maddini",
      "Vandna Sharma ",
      "Hasuben Kapletiya",
      "Raman Khanna",
      "venkatashyam madasani",
      "sirisha Rajesh",
      "Dharmesh Patel",
      "Fozia Ashraf",
      "Ambika Singh",
      "Rajeev Kumar",
    ],
  },
  {
    golden: "Puja <br />Khanna",
    tabName: "Week 1",
    winners: [
      "Sudhakar Yadala",
      "Snehpreet Kaur",
      "gurleen Kaur",
      "Sarah Kaleem",
      "Amritha Bhat",
      "Sandeep Kalsi",
      "Bharat Garate ",
      "Muhammad Zahid",
      "Raj B",
      "Prasanta Sahu",
      "Azam peter",
      "thinley gurung",
      "Hemal Patel",
      "Uma Godavarthi",
      "Vijay Sawardekar",
      "lalita patel",
      "Jawairia Razi",
      "Smita Patel",
      "Amit Patel",
      "Anima Mishra",
      "Gul Nasreen",
      "Muneeba Ali",
      "Iram Shahid ",
      "Saad Khan",
      "Kulbir Singh",
      "Hasuben Kapletiya",
      "Naresh Gaur",
      "Iqtidar Akram",
      "Savitha Reddy",
      "Varsha Dhawale",
      "Muhammad Arslan",
      "Neha Shah",
      "Aum Patel",
      "Abdul Qayyum",
      "Indu  Badyal ",
      "Aksh Patel",
      "Durgesh AGRAWAL",
      "Haritha S",
      "Shariq Husain",
      "RAJESHKUMAR TAILOR",
      "Amani Reddy ",
      "Rajasekar Thangaraj",
      "S J",
      "A K",
      "Narinder Kaur",
      "Muhammad Khan",
      "Munirul Hassan ",
      "Rahul Munjal",
      "Shobhana Shah",
      "Abduljabbar Ahmed",
      "gurleen Kaur",
      "Robin Mishra",
      "Snehal Patel",
      "Nauman Sarfraz",
      "Sabiha Firdaus",
      "Priti Patel",
      "Nitin Patel",
      "Shobhna Patel",
      "Kinjal Maheshwaria",
      "akhtar ghadiyali",
      "Hasan Abbasi",
    ],
  },
];
export const megawinnerData = [
  {
    tabName: "Week 12",
    winners: [
      "Ramu Rajula",
      "Yasaswi Kolasani",
      "Parvesh Parvesh",
      "Satheiish Vaidyanathan",
      "Tirumala Nidamanuri",
      "Swetha Gadamsetty",
      "Ekta Paliwal",
      "VIJAYKUMAR NAYEE",
      "Akash Karda",
      "Pooja Gouroji",
      "Suhasi Goly",
      "Hasuben Kapletiya",
      "Sunil Aryal",
      "Ashok Rayavaram",
      "Adarsh Kumar",
      "Sandeep Kapur",
      "Swarna ban",
      "Aum Patel",
      "Sangita Patel",
      "Sukesh Parikh",
      "Dhanalakshmi Tammineni",
      "Deepak Tiwari",
      "Mahalakshmi Gurram",
      "Sai kiran Munugoti",
      "abhimanyu bakshi",
      "Niveditha Namilikonda",
      "Rajiv Patel",
      "Lavanya Madduri",
      "FNU Ananda",
      "Rajinder Maan",
      "Darshan akula",
      "Priteshkumar Shah",
      "Pooja Engle",
      "Shilpa Yeradla",
      "Aum Patel",
      "Praveen Dodda",
      "venkatesh mahendrakar",
      "Payal Jain",
      "Summaiya Arshad",
      "Alpa Patel",
      "Mrudula Wadake",
      "Venkata Vemparala",
      "Asma Dabheliya",
      "Abdul Samiullah",
      "Pradeep Ch",
      "Jeevan Yelety",
      "SANDEEP CHINTAPATLA",
      "Mahesh Wadhe",
      "Ramesh Vepakomma",
      "priyanksinh kapletiya",
      "Vinaben Joshi",
      "Karthik Pamulaparthy",
      "Aakanksha Vyawahare",
      "Vijayalakshmi angaiyan",
      "Gurmit Ram",
      "Pratik Lole",
      "Supreet Tomar",
      "Sanjay Verma",
      "Rabia Gulkaya",
      "Shalinder Tiku",
      "Pranay Vennapusa",
      "Bhargavi Rallabandi",
    ],
  },
  {
    tabName: "Week 11",
    winners: [
      "Sultana Lalani",
      "YOGI SUTHAR",
      "Vahini	Potluri",
      "Sushmitha Kodavati",
      "Divya Kalaria",
      "Kawaljeet Singh",
      "Kishore Ganipineni",
      "Shikha Parekh",
      "Prithvi Kocherla",
      "Shankar  Acharya ",
      "NAGA SASANAPURI",
      "Aisha Kalhoro",
      "Shankar Acharya ",
      "KANTI RANA",
      "Naga Manda",
      "aum patel",
      "Hemant Patil",
      "Nishkarsh Jain",
      "Vivek Kumar",
      "Asmeeta Manaseeya ",
      "Nitin Mohan",
      "venkata Gangavarapu ",
      "Kapil Chintala",
      "Hema Patel",
      "Nagababu Mareedu",
      "Amita Suthar",
      "Aameerah Ginwala",
      "Ashish Umrani",
      "Sureshbhai Patel",
      "Jaikumar Sharma",
      "Chandini Boyina",
      "Rakesh Patel",
      "Madiha Butt",
      "Kuldeep Kaur",
      "Gowtham Mallepaka",
      "Shekhar Singh",
      "Gangadhar Bonda",
      "veerareddy Kolan",
      "Swapna Vanga",
      "Casey Boyd",
      "ILIYAS HUSSAIN",
      "Pavan Kothapalli",
      "Indranil Dasgupta",
      "Ramalakshmi Neti",
      "Raja Mani",
      "Money Madineni",
      "Abhay Dare",
      "Kushavantha Kyanahalli ",
      "Nambirajan Jayaraman",
      "Pratikkumar Patel",
      "Chirun Ananthaneni",
      "Vineeta Bhagchandani",
      "Deval Thakkar",
      "Parame Boddu",
      "Sowndrapriya Muruganantham ",
      "priyanksinh kapletiya",
      "Manohar Reddy",
      "vasu chemuru",
      "Satyanarayana Satti",
      "Kalyani Mothukuri",
      "Deepali Patil",
      "Jinal Joshi",
    ],
  },
  {
    tabName: "Week 10",
    winners: [
      "Sasidhar Damireddy",
      "Harneet Khanna",
      "Senthil Rajendarn",
      "Sai Kotcharlakota ",
      "Nagaraj Thakur",
      "Mohan ganesan",
      "Chinmay Patel",
      "Nithin Mali",
      "Kuldeep Sharma",
      "Rohith Jetti",
      "Joga Singh",
      "DIVYA KOTA",
      "Ashwin Patel",
      "Bijjol R",
      "Krishna Vemuri",
      "Muktesh <br />Mistry",
      "Chander Sethi",
      "Lohit Bannaravuri",
      "Swathi Loka",
      "Sushma Singh",
      "Pratik Lole",
      "Amardeep Singh",
      "suresh muppalla",
      "Sridivya yella",
      "I Singh ",
      "Bibi Khan",
      "Dev jaisi",
      "Anand Kumar",
      "Dunellaben Patel",
      "Rajesh Patel",
      "Mukul Sathe",
      "SANDEEP KALARI",
      "Mehreen K",
      "Aakanksha Patel",
      "Swathi Ananthala",
      "Ramalingam Sekar",
      "Manoj Patel",
      "Naga Kollepara",
      "Hema Yalamanchi",
      "Arun Illendula",
      "Nurjaha <br />Dinani",
      "Dravidaselvi Loganathan ",
      "phanindra guthi",
      "Alka Satpalkar",
      "Shaila Patel",
      "Sadhanaben Patel",
      "Jeya Akula",
      "GIRISH DALVI",
      "Ramnath Desa",
      "MADHUSUDHANA CHIPPAGIRI",
      "Harbhajan Singh",
      "Anand Kumar",
      "Jayakumar Nallapalli",
      "Sunil Ali",
      "Huma Mohammed",
      "Virnica Gautam",
      "Nishantkumar Patel",
      "ANUVRAT JAINDUNGARWAL",
      "Sudharani Rajamanyam",
      "Ajay Potharaju",
      "veera manda",
      "Maulik Patel",
    ],
  },
  {
    tabName: "Week 9",
    winners: [
      "Ramya Maguluri",
      "Lesli Faras",
      "Nilesh Thacker",
      "Ameer Shohatee",
      "srinivas n",
      "Bhumika Patel",
      "Sunil Mutyala",
      "VINEET Bhasin",
      "Rabia Farooq",
      "Divya Bodapati ",
      "Siddhesh Parab",
      "Sunil Sahu",
      "Swathi Beeravelli",
      "Prasanth Madduri",
      "Amol Gupta",
      "Anil Rajput",
      "bhavana kollimarla",
      "Veera Kongalla",
      "Karun Vinay",
      "Fnu Devanshu",
      "Ashwini Mundhe",
      "Jagdeep singh",
      "Venkata Miriyala",
      "Hansa Patel",
      "Neha Chauhan",
      "Niti Sharma",
      "Sahithi Bhatt",
      "Vishwanath Narake",
      "Vanajadevi Cherevuru",
      "Shazia SABIR",
      "Archana Bandaru",
      "Charanjit Singh",
      "Ashish Galav",
      "PoojaNag Nagaraju",
      "naveen anamaneni",
      "Divya bollineni",
      "sai billa",
      "KULWANT SANGHA",
      "Jaspal Singh",
      "Neha Narula",
      "Vishalkuamr Mandaliy",
      "Govind Jaipuria",
      "Srikanth Muppala",
      "Sunita Bhandare",
      "Abdullatheef Abdulrahman",
      "Nadiya Parekh",
      "Krishna Amaravadi",
      "Sree Kovuru",
      "Richa sharma ",
      "Muskan Aga",
      "Gaurav Gupta",
      "Lakshmi Ravi",
      "Brenda Bussolaro ",
      "Chetana Patel ",
      "Divya Bajwa",
      "Sandhya Vempati",
      "Anand Kumar ",
      "Bilal Anarwala",
      "Sanjay Gurram ",
      "Raunak Kumar",
      "Mahith Adapala",
      "Sukanya Shankara",
    ],
  },
  {
    tabName: "Week 8",
    winners: [
      "Viralkumar Patel",
      "shailaja pottu",
      "Anand Kumar ",
      "Kavitha Rao",
      "SUBODH DESHPANDE",
      "Maram Mohd",
      "Krushi Shah",
      "Karthik Aravapalli",
      "Aarti Gupta",
      "Krithin  Palla",
      "sandeep gunnam",
      "Swathi Nampally",
      "Sudipta Mukherjee",
      "Rahul Vashisht",
      "James Mughal",
      "Zaharaben  Maknojia ",
      "Nilaykumar Patel",
      "Bhavik Thakkar",
      "HARIPRASAD MUSTYALA",
      "Chandra Palle",
      "Jashandeep Singh",
      "ISHAN JINDAL",
      "BHAVESHKUMAR PATEL",
      "Vikas Desraj",
      "Pranav Shah",
      "Rajnish Rajnish",
      "Shahena Akter",
      "Khyati Shah",
      "Amit Bansal",
      "Lavanya Trivedi",
      "Praveen Nallana",
      "Riya Babvani",
      "Sahejdeep Singh",
      "santosh khule",
      "Saajan Cheema",
      "Barkat Lalani",
      "Naga Mamillapalli",
      "Kiritkumar Patel",
      "Hasuben Kapletiya",
      "Bikash Behera",
      "Pranati Nath",
      "Sima Lalani ",
      "Soniya Goyal",
      "jyothi Vasamshetty",
      "Shila Desai",
      "Abhiram Boyapati",
      "Mounika N",
      "Ajay Sharma",
      "Gajendrasinh Kapletiya",
      "Aman Singh",
      "Davinder Gill",
      "Manisha Gaddam",
      "Milind Naik",
      "Praveen Katukuri",
      "Ranjith Chinni",
      "Sushma Dindu",
      "Priti Patel",
      "Sunny Gandhi",
      "Vinay Rao Bas",
      "Sanjiv Shah",
      "Shashi Sharma",
      "Urmila Gupta",
    ],
  },
  {
    tabName: "Week 7",
    winners: [
      "Subhani Shaik",
      "Mariah Dhalai",
      "Husna Kh",
      "Yaswanth Kodali",
      "Mandeep Singh",
      "Manjunatha Jayakumar",
      "Vijay Kose",
      "Sruti Nanda",
      "Rohit Saxena",
      "Yogita Kumari",
      "Fnu Hamidullah",
      "Heena Patel",
      "Prabha Wati",
      "Ali Mohamed",
      "Chandrakanth Barad",
      "Ram Yar",
      "Nishant Jain",
      "Vinay Thatiparthy",
      "Chandani Patel",
      "Sahitya Mehra",
      "Bhavana Sarawagi",
      "Vijay Koti",
      "Raaghu  Thippeswamy",
      "Nagaraju Chappa",
      "Chandan Kumar",
      "Raghu Yalamanchili",
      "Tushar Joshi",
      "Kainat Ghazal",
      "Indumathi Aravapalli",
      "Akhila Kathari",
      "Madhu Karthikeyan",
      "Venkata Javvaji",
      "Kalyan Nimma",
      "Kiran Bhaskar",
      "Karthikeyan Vaiyapuri",
      "Saran Thanikonda",
      "Shivateja Tadaka",
      "Snehal Naidu",
      "Deepak Deshpande",
      "Swathi Maddali",
      "Shilap Chen",
      "Naga Kommidi",
      "Swapna Ketavarapu",
      "Rajan Dobariya",
      "Zahara Hooda",
      "Anusha Hindka",
      "Bhavana Mendu",
      "Shailaja Newaskar",
      "Sagar Narala",
      "Mayank Mehrishi",
      "Sunny Ahir",
      "Anand Hirawat",
      "Yamini Chigurupati",
      "Harjit Kaur",
      "Sheza Taranum",
      "Rajat Kamble",
      "Sunil Kumar",
      "Vasavi Vysyaraju",
      "Lipika Sahoo",
      "Damodar Nayana",
      "Jagpreet Dhaliwal",
      "Dhanesh Khatri",
    ],
  },
  {
    tabName: "Week 6",
    winners: [
      "Seema Kumari",
      "Pavan Kancheti",
      "Deep Reddy",
      "Uma Ulisi",
      "TamilIlakkiya Arulmurugan",
      "Saumya Agrawal",
      "Sreenivas Gudipati",
      "Mounika Nallamothu",
      "Venkateshwary Pasumarthy",
      "Ken David",
      "Nileshvari Patel",
      "Amin Noorani",
      "Ravi Sharma",
      "Md Tamjid adnan",
      "Yeswanth Lekkala",
      "Venki Manoharan",
      "Hiral Patel",
      "Vandana Rajpurohit",
      "Chanchal Gill",
      "Rupal Trivedi",
      "Muhammad Ramzan",
      "Krishna Bojanki",
      "Muhammad SHAHID",
      "Vinaykumar Dori",
      "Sreedhar Bunga",
      "FNU manoj",
      "Sai Anirudh Garnapally",
      "Kumar Robin",
      "Silpa Polavarapu",
      "Sahista Mansuri",
      "Vijayakumar Rajasekaran",
      "Bhargavi Lavanya",
      "KAPIL NAGDIA",
      "Dalveer Kaur",
      "Nitu Ravi Verma",
      "Samina Chaudhary",
      "Abid Ramani",
      "Preeti Sidhu",
      "Sree Rotte",
      "padmaja mallu",
      "Rakesh Mehta",
      "Ram G",
      "Soni Kumari",
      "Saroj Rani",
      "Mustan Singh",
      "manu patel",
      "Bakkiyalakshmi Dhanasekar",
      "Ansul Mudbhari",
      "Rupal Patel",
      "Venkateswarlu Namburu",
      "Neeraj Bhatia",
      "CHANDRASEKAR SEMBAN",
      "Sunil Kumar",
      "Zainab Muthanna",
      "Seethend Dummansoor",
      "Madhu Kapoor",
      "Mahesh Kandregula",
      "M. Wajid Aleem",
      "Meenakshi Honkanadavar",
      "Kajal Kukadiya",
      "Shameem Ameerudeen",
      "Kartik Shah",
    ],
  },
  {
    tabName: "Week 5",
    winners: [
      "Khuram Shahzad",
      "Ravi Ambikapathy",
      "Neha Tare",
      "Sumandeep Ghotra ",
      "Hari Kalkoda",
      "Prem Pandey ",
      "Naman Patel",
      "Srinivasa Tatikonda ",
      "Ambika Rani",
      "Muhammad Kalim",
      "chinna tanikonda",
      "Dharti Patel",
      "Aruna Baraiya ",
      "Ambreen Danish",
      "Snehal Sathawane",
      "Kajal Shah",
      "Praveen Kasireddy",
      "Tejas Raviraj",
      "saritha vasireddy",
      "Yateesh Shukla",
      "Ramanujan Archakam",
      "Sai Pasupuleti ",
      "Rajesh Vandanapu",
      "Rozina Moiz",
      "Vanita Gandhi",
      "Jagdeep Singh",
      "Bindu Devalla",
      "Sukhjeet Singh",
      "Vidhyarubini Nallusamy ",
      "Manisha Hanspal",
      "Neha Rani",
      "Khyati Vaghela ",
      "Radhika Siriki",
      "Devanshi Patel",
      "Naveenkumar Vuppu",
      "Richa Varshney ",
      "Pranjal Mahant",
      "Yash Trivedi",
      "Ravi Thummalapally ",
      "Barkha Soni",
      "Madhulika Verma",
      "Sreeja Boppana",
      "Ankitkumar Patel",
      "Hina Deliwala",
      "Neeraja Yarrabothula",
      "Vibhali Jagtap",
      "Karuna M",
      "Ravinder Ravinder",
      "Himaja Sunkara",
      "Tithi Thakker",
      "Shivam Patel",
      "Sushil Jain",
      "Satya Beri",
      "Parita Patel ",
      "Shree Raut",
      "NAVEEN VANAMALA",
      "Suresh Kaushik",
      "RAMA MANNEM",
      "HITESH DUDHAT",
      "Cecil christian",
      "Nikitha Nagulapati ",
      "Anjani Prabaharan",
    ],
  },
  {
    tabName: "Week 4",
    winners: [
      "Pruthvi Manjunatha",
      "Ahamadulla Shaik",
      "Vidwan Patlolla",
      "Eshaan Yadlapalli",
      "Krishna Pavuluri",
      "Gagan Vyas",
      "Lakshmi Narra",
      "Subhalakshmi Ramamoorthy",
      "Raghavendra Thavva",
      "Simaben Patel",
      "Hemanth Permareddy",
      "Satish Bogaiah",
      "Sulekha Gautam",
      "Krinesh Patel",
      "Falguni Patel",
      "Arvind Agrawal",
      "Ritu Parekh",
      "Gulshan Dhamani",
      "Priyal Patel",
      "Venugopal Moparthy",
      "Harika Jonnadula",
      "Jagdish Patel",
      "Jagdish Patel",
      "Anil Murari",
      "Ishtiyaq Ahmad",
      "Pradeep Kumar",
      "Sandhya Chandran",
      "Ranjini Kadarkarai",
      "Anand Sivakumar",
      "Mukthiswar Velagandula",
      "Padmaja Jami",
      "Raviraj Tippannavar",
      "Anand Kumar",
      "Bhavna Oswal",
      "Vivek Mara",
      "Anoop Aribandi",
      "Rameshbhai Patel",
      "Sagar Patel",
      "Vibha Shukla",
      "Chhaya Patel",
      "Madhu Bala",
      "Ruchi Patel",
      "Balaji Kandavel",
      "Neelakshi  Dhote",
      "Vijay Sankar",
      "Anudeep Simhadri",
      "Vidhi Mehta",
      "Sumaira Ayub",
      "Emeraldine Isaac",
      "Hemang Pokal",
      "Shweta Mudke",
      "Vikas Khandelwal",
      "Ashish Kishore",
      "Praneeth Madamsetty",
      "Varalakshmi Boddu",
      "Ashvin Patel",
      "Syed Zedi",
      "Shalini Komaragiri",
      "Varun Haria",
      "Sridhar Madala",
      "Sarika Dachepalli",
      "Sravanthi Vunnam",
    ],
  },
  {
    tabName: "Week 3",
    winners: [
      "Nisarg Patel",
      "jagrut thakkar",
      "ABDUL MUNSHI",
      "Rupa Desai",
      "N Hameed",
      "Sagarika Rajguru",
      "Mahesh Bhogadi",
      "Sheik Shafia",
      "Swapnil Phapale",
      "Nirali Patel",
      "Vijayakumar Radhakrishnan",
      "Kapil Gandhi",
      "Mohit Sehgal",
      "Avtar Singh",
      "Pankajkumar Patel",
      "Jagadeesh Koppisetti",
      "Hareshkumar Ramani",
      "Syed Hussain",
      "Jeewan Ram ",
      "Ramesh Tottadi",
      "Ar SH",
      "vaishali Chitalkar",
      "Anisha Mahajan",
      "Nitin Sharma",
      "Srinivas Ambati",
      "Prabhavati Barvalia",
      "Sunil Singh",
      "Chandra Pat",
      "Devendra Yadav",
      "Prasanth Pagadala",
      "Anand Kumar",
      "Phani Kalvakota",
      "vamsi ponnada",
      "Prince Sharma",
      "inderjeet kattaria",
      "Yorusalem Yohannes",
      "Jayesh Panchal",
      "Jagan Kanniappan",
      "Sushant Mahajan",
      "Nilesha Patel",
      "Shoukat Tufail",
      "Aruna Baraiya",
      "Kishore Kottalanka",
      "Poonam Parmar",
      "Abhaya Kulkarni",
      "Ojaswa Swarnkar",
      "Muhammad Shaheen",
      "Lalita Sharma",
      "Pawan Kumar",
      "Manjinder Kaur",
      "padma Bommareddy",
      "Rahul Jain",
      "Jayalaxmi Jungula",
      "Nisha Sharma",
      "Suday Mandal",
      "Nafisa Gajra",
      "Bhadreshkumar Bhuva",
      "Richa Jindal",
      "Vijay Mindhe",
      "Monica Khera",
      "Alex Patel",
      "Anup Rana",
    ],
  },
  {
    tabName: "Week 2",
    winners: [
      "Kamal Lalka",
      "Vruti Patel",
      "Cheng Ma",
      "Sagar Bhavsar",
      "Surya Palle",
      "Karan  Kapoor",
      "Sidra Batool",
      "Nishiba Riaz",
      "xapz xapz1",
      "Devikaben  Shah",
      "jigneshkumar mehta",
      "Siva Gottimukkala",
      "Prashanth Veeramalla",
      "Jyoti Sharma",
      "Balwinder  Singh",
      "Aparna Basani",
      "Anand Kumar ",
      "Siddhartha Konakondla",
      "ads laxmi",
      "Shaileshbhai Shah",
      "Ebenezer Kanamala",
      "Miguel Organillo",
      "Ankita Kolhe",
      "Munish  Kumar",
      "Nidhi Vyas",
      "Venmukil Mathivanan ",
      "Grishmaben Patel",
      "Raja Bikumandla",
      "Yasoda Namala",
      "Vruti Patel",
      "Amit Kumar",
      "Lohitha Kommuri",
      "Sayali Patil",
      "Naga Vulli",
      "Shaileshbhai Shah",
      "Shaileshbhai Shah",
      "Khyati Patel",
      "awais Mahmood",
      "Vikas Thange",
      "Joseph Reddy",
      "Sayroj Samnani",
      "Hiren Gajipara",
      "Neha Asthana",
      "Kuljit  Kaur ",
      "Stephen  Francis",
      "Radhika Nijampurkar",
      "Sabina Yeahia",
      "Fatima Azeem",
      "Abhilash Lallapati",
      "Jasvir Kaur",
      "MOHAMMAD HOQUE",
      "Vamshi Poladi ",
      "Kishore Maddini",
      "Vandna Sharma ",
      "Hasuben Kapletiya",
      "Raman Khanna",
      "venkatashyam madasani",
      "sirisha Rajesh",
      "Dharmesh Patel",
      "Fozia Ashraf",
      "Ambika Singh",
      "Rajeev Kumar",
    ],
  },
  {
    tabName: "Week 1",
    winners: [
      "Puja Khanna",
      "Sudhakar Yadala",
      "Snehpreet Kaur",
      "gurleen Kaur",
      "Sarah Kaleem",
      "Amritha Bhat",
      "Sandeep Kalsi",
      "Bharat Garate ",
      "Muhammad Zahid",
      "Raj B",
      "Prasanta Sahu",
      "Azam peter",
      "thinley gurung",
      "Hemal Patel",
      "Uma Godavarthi",
      "Vijay Sawardekar",
      "lalita patel",
      "Jawairia Razi",
      "Smita Patel",
      "Amit Patel",
      "Anima Mishra",
      "Gul Nasreen",
      "Muneeba Ali",
      "Iram Shahid ",
      "Saad Khan",
      "Kulbir Singh",
      "Hasuben Kapletiya",
      "Naresh Gaur",
      "Iqtidar Akram",
      "Savitha Reddy",
      "Varsha Dhawale",
      "Muhammad Arslan",
      "Neha Shah",
      "Aum Patel",
      "Abdul Qayyum",
      "Indu  Badyal ",
      "Aksh Patel",
      "Durgesh AGRAWAL",
      "Haritha S",
      "Shariq Husain",
      "RAJESHKUMAR TAILOR",
      "Amani Reddy ",
      "Rajasekar Thangaraj",
      "S J",
      "A K",
      "Narinder Kaur",
      "Muhammad Khan",
      "Munirul Hassan ",
      "Rahul Munjal",
      "Shobhana Shah",
      "Abduljabbar Ahmed",
      "gurleen Kaur",
      "Robin Mishra",
      "Snehal Patel",
      "Nauman Sarfraz",
      "Sabiha Firdaus",
      "Priti Patel",
      "Nitin Patel",
      "Shobhna Patel",
      "Kinjal Maheshwaria",
      "akhtar ghadiyali",
      "Hasan Abbasi",
    ],
  },
];
