import testiimg2 from "../../images/landing-page/testiimg2.png";
import testiimg3 from "../../images/landing-page/testiimg3.png";
import testiimg4 from "../../images/landing-page/testiimg4.png";
import anjana from "../../images/landing-page/testi/anjana.png";
import mariumArif from "../../images/landing-page/testi/Marium Arif.png";
import ayesha from "../../images/landing-page/testi/Ayesha.png";
import kunalLamba from "../../images/landing-page/testi/Kunal Lamba.png";
import aarthiSampath from "../../images/landing-page/testi/Aarthi Sampath.png";
import pujaReza from "../../images/landing-page/testi/PujaReza.png";
import omiVaidya from "../../images/landing-page/testi/Omi Vaidya.png";
import ashaShiv from "../../images/landing-page/testi/Asha Shiv.png";
import unmuktChand from "../../images/landing-page/testi/Unmukt Chand.png";
import rabiaAmmar from "../../images/landing-page/testi/Rabia Ammar.png";
import {
  AkbarPirani,
  BhavanaPatel,
  DianaHayden,
  FamilyKarma,
  FatimaAli,
  MsPooja,
  NikhilMerchant,
  Priya,
  Samira,
  ShubhraRamineni,
} from "../../images";

export const testimonialData = [
  {
    ytLink: "https://www.youtube.com/embed/nF341X8dbv4",
    img: Samira,
    testoDesc: "Samira",
  },
  {
    ytLink: "https://www.youtube.com/embed/taOtMgrvgB8",
    img: MsPooja,
    testoDesc: "Ms. Pooja",
  },
  {
    ytLink: "https://www.youtube.com/embed/gf_uxRzbLYE",
    img: DianaHayden,
    testoDesc: "Diana Hayden",
  },
  {
    ytLink: "https://www.youtube.com/embed/7Mx--czTcNI",
    img: NikhilMerchant,
    testoDesc: "Nikhil Merchant",
  },
  {
    ytLink: "https://www.youtube.com/embed/bQ8rYqShKWo",
    img: FamilyKarma,
    testoDesc: "Family Karma",
  },
  {
    ytLink: "https://www.youtube.com/embed/3LZAhr7vYVc",
    img: Priya,
    testoDesc: "Priya",
  },
  {
    ytLink: "https://www.youtube.com/embed/oKyTOjyEKpw",
    img: BhavanaPatel,
    testoDesc: "Bhavana Patel",
  },
  {
    ytLink: "https://www.youtube.com/embed/EzEyiqJH3KE",
    img: ShubhraRamineni,
    testoDesc: "Shubhra Ramineni",
  },
  {
    ytLink: "https://www.youtube.com/embed/M0tG7a5Jj90",
    img: AkbarPirani,
    testoDesc: "Akbar Pirani",
  },
  {
    ytLink: "https://www.youtube.com/embed/GhJeONhTZrk",
    img: FatimaAli,
    testoDesc: "Fatima Ali",
  },
  {
    ytLink: "https://www.youtube.com/embed/jsgsnTLF4_M",
    img: testiimg2,
    testoDesc: "Paratha Paaji",
  },
  {
    ytLink: "https://www.youtube.com/embed/6OdcvAQBzaA",
    img: testiimg3,
    testoDesc: "Biryani Bill",
  },
  {
    ytLink: "https://www.youtube.com/embed/vq69QXytA1k",
    img: testiimg4,
    testoDesc: "Laxmi celebrates glorious 50 years",
  },
  {
    ytLink: "https://www.youtube.com/embed/ATGjgb2OyNQ",
    img: anjana,
    testoDesc: "Anjana",
  },
  {
    ytLink: "https://www.youtube.com/embed/njzY3BZvcZ8",
    img: mariumArif,
    testoDesc: "Marium Arif",
  },
  {
    ytLink: "https://www.youtube.com/embed/kv_h7KQ9aQI",
    img: ayesha,
    testoDesc: "Ayesha",
  },
  {
    ytLink: "https://www.youtube.com/embed/f6pGQ0L4qjw",
    img: kunalLamba,
    testoDesc: "Kunal Lamba",
  },
  {
    ytLink: "https://www.youtube.com/embed/Jz4ombve5ew",
    img: aarthiSampath,
    testoDesc: "Aarthi Sampath",
  },
  {
    ytLink: "https://www.youtube.com/embed/X6M2v9MqSe4",
    img: pujaReza,
    testoDesc: "Puja & Reza",
  },
  {
    ytLink: "https://www.youtube.com/embed/ib2ETw7pWTI",
    img: omiVaidya,
    testoDesc: "Omi Vaidya",
  },
  {
    ytLink: "https://www.youtube.com/embed/Jcx8DxXUUIw",
    img: ashaShiv,
    testoDesc: "Asha Shiv",
  },
  {
    ytLink: "https://www.youtube.com/embed/Ze1gNyZ3E6k",
    img: unmuktChand,
    testoDesc: "Unmukt Chand",
  },
  {
    ytLink: "https://www.youtube.com/embed/wHPUqtledAc",
    img: rabiaAmmar,
    testoDesc: "Rabia Ammar",
  },
];

export const weeklyWinners = [
  {
    winnerName: "Gaurav Raj Lamichchane",
  },
  {
    winnerName: "Usha Ghimire",
  },
  {
    winnerName: "Nupur Chakrovarthy",
  },
  {
    winnerName: "Rahul Anandan ",
  },
  {
    winnerName: "Rosa Mathew",
  },
  {
    winnerName: "Priya Kulkarni",
  },
  {
    winnerName: "Niharika P",
  },
  {
    winnerName: "Kavya Chandre Gowda",
  },
  {
    winnerName: "Dr. Varsha Singh",
  },
  {
    winnerName: "Vishlesha Rana",
  },
  {
    winnerName: "Alina Alam",
  },
  {
    winnerName: "Gayathri Murthy",
  },
  {
    winnerName: "Divya Prabhakar",
  },
  {
    winnerName: "Mangala Gowri Subramanian",
  },
  {
    winnerName: "Dhruv Patel",
  },
  {
    winnerName: "Karishma M Gaitonde",
  },
  {
    winnerName: "Sujata Shukla",
  },
  {
    winnerName: "Ankush Jain",
  },
  {
    winnerName: "Sarah Siddiqui",
  },
  {
    winnerName: "Kanupriya Gupta Mujawdiya",
  },
  {
    winnerName: "Abhishek Parekh",
  },
  {
    winnerName: "Ganell Morris",
  },
  {
    winnerName: "Nupura Kulkarni",
  },
  {
    winnerName: "Viji",
  },
  {
    winnerName: "Narmada Sirasanambedu",
  },
  {
    winnerName: "Yama Bhanu Prakash Reddy",
  },
  {
    winnerName: "Saman Khan",
  },
  {
    winnerName: "Aishwarya Ramakrishnan",
  },
  {
    winnerName: "Sakshi Bhalla",
  },
  {
    winnerName: "Shrushti Parikh",
  },
];
