export const diwali = "/laxmi-golden-jubilee-bonanza";
export const diwaliwinner = "/laxmi-golden-jubilee-bonanza/winners";
export const diwaliabout = "/laxmi-golden-jubilee-bonanza/about";
export const diwaliform = "/laxmi-golden-jubilee-bonanza/enter-your-code";
export const diwaligallery = "/laxmi-golden-jubilee-bonanza/gallery";
export const diwalithankyou = "/laxmi-golden-jubilee-bonanza/thank-you";
export const winners = "/laxmi-golden-jubilee-bonanza/winners"
export const megawinners = "/laxmi-golden-jubilee-bonanza/mega-winners"
export const diwalitermscondition = "/laxmi-golden-jubilee-bonanza/terms-and-conditions";
export const termsandconditions = "/terms-and-conditions";
